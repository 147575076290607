import React from 'react';
import { SelectMenuItemGroup } from '../../../types/components.types';
import { SelectActionMenuItemContents } from '../select-action-menu-item-contents/select-action-menu-item-contents.component';

interface CreateMenuItemsProps {
  menuGroups: SelectMenuItemGroup[];
}

export const createMenuItems = ({ menuGroups }: CreateMenuItemsProps) =>
  menuGroups.flatMap((group, groupIndex) =>
    group.menuItems.map((item, itemIndex) => {
      const actionMenuItem = (
        <SelectActionMenuItemContents
          key={item.id}
          selected={item.selected}
          showDivider={
            menuGroups.length > 1 &&
            itemIndex === group.menuItems.length - 1 &&
            groupIndex !== menuGroups.length - 1
          }
          disabled={Boolean(item.disabledTooltip)}
        >
          {item.label}
        </SelectActionMenuItemContents>
      );
      return { ...item, label: actionMenuItem };
    }),
  );
