export const EN_STRING_ACTIVITY_OVERRIDES = {
  ACTIVITIES: '{count, plural, one {Activity} other {Activities}}',
  SELECTABLE_TABLE_ROW_STATUS_HEADING: 'Selected status',
  ACTIVITY_ICON_URL: 'Icon URL',
  ACTIVITY_CTA_URL: 'CTA URL',
  ACTIVITY_CTA_TEXT: 'CTA Text',
  ACTIVITY_JOURNEY_TOOLBOX: 'Health Journey - Toolbox Activity',
  /* META DATA SECTION */
  ACTIVITY_META_DATA: 'Metadata',
  ACTIVITY_INTERNAL_NAME_HINT:
    'This is used to refer to the Activity internally. It will not be seen by the user.',
  ACTIVITY_TYPE_SELECT_PLACEHOLDER: 'Select Activity Type',
  ACTIVITY_CLICK_THROUGH: 'Click-through',
  ACTIVITY_NO_TOUCH: 'No-touch',
  /* CARD DATA SECTION */
  ACTIVITY_CARD_DATA: 'Card',
  ACTIVITY_TAGLINE: 'Tagline',
  ACTIVITY_TAGLINE_HINT:
    'Displays a short message at the top of the card when it is Medium or High priority.',
  ACTIVITY_PRIORITY_WEIGHTING: 'Priority Weighting',
  ACTIVITY_PRIORITY_WEIGHTING_HINT:
    'Determines which label and presentation will be displayed on the Activity card.',
  ACTIVITY_PRIORITY_WEIGHTING_SELECT_PLACEHOLDER: 'Select Priority Weighting',
  ACTIVITY_DISPLAY_WEIGHTING: 'Display Weighting',
  ACTIVITY_DISPLAY_WEIGHTING_HINT:
    'Determines how much detail is added to the Activity card',
  ACTIVITY_DISPLAY_WEIGHTING_SELECT_PLACEHOLDER: 'Select Display Weighting',
  ACTIVITY_IMAGE_ID: 'Activity Image',
  /* CONTENT DATA SECTION */
  ACTIVITY_CONTENT_DATA: 'Content',
  ACTIVITY_SHORT_DESCRIPTION: 'Short Description',
  ACTIVITY_TITLE_HINT: 'User facing title for the Activity',
  ACTIVITY_PAGE: 'Page',
  ACTIVITY_PAGES: 'Pages',
  ACTIVITY_PAGES_HINT: 'This describes the user experience of the Activity.',
  ACTIVITY_PAGE_HINT:
    'This describes the user experience of the Activity by page.',
  ACTIVITY_INTERNAL_PAGE_NAME: 'Internal Page Name',
  ACTIVITY_INTERNAL_PAGE_NAME_HINT:
    'Not user facing. Used to identify this page within configuration tools.',
  ACTIVITY_CALL_TO_ACTION: 'Call to Action',
  ACTIVITY_CALL_TO_ACTION_HINT:
    'The button to display to the user on this page.',
  ACTIVITY_URL: 'URL',
  ACTIVITY_TEXT: 'Text',
  ACTIVITY_SUB_TEXT: 'Sub-Text',
  ACTIVITY_COMPONENTS: 'Components',
  ACTIVITY_COMPONENTS_HINT:
    'Each component appears in the order in which they appear below.',
  ACTIVITY_BRANCHING_LOGIC: 'Branching Logic',
  ACTIVITY_BRANCHING_LOGIC_HINT:
    'This section is used to add branching logic for components in this page.',
  ACTIVITY_RULE_HINT: 'Add logic to this page using the editor below.',
  /* ASSIGNMENT DATA SECTION */
  ACTIVITY_ASSIGNMENT_DATA: 'Assignment',
  ACTIVITY_REQUIRED: 'Required',
  ACTIVITY_REQUIRED_HINT:
    'Determines if this Activity is required to be completed in a parent Program.',
  ACTIVITY_REMOVABLE: 'Removable',
  ACTIVITY_REMOVABLE_HINT: 'Determines if the user can delete this Activity.',
  ACTIVITY_DURATION: 'Duration',
  ACTIVITY_DURATION_HINT:
    'Defines the time frame in which the Activity can be completed.',
  ACTIVITY_START_DATE: 'Start Date',
  ACTIVITY_END_DATE: 'End Date',
  ACTIVITY_ELIGIBILITY_CRITERIA: 'Eligibility Criteria',
  ACTIVITY_ELIGIBILITY_CRITERIA_HINT:
    'This field is used to determine if a user is eligible for this activity, assuming they are eligible for its parent program. If this is changed after publish, the parent campaign will need to be republished.',
  ACTIVITY_COMPLETION_BANNER_TITLE: 'Attention needed',
  ACTIVITY_COMPLETION_BANNER_DESCRIPTION:
    'This section is only to be completed with a No-Touch Activity',
  /* COMPLETION DATA SECTION */
  ACTIVITY_COMPLETION_DATA: 'Completion',
  ACTIVITY_COMPLETION_HINT:
    'Only relevant to no-touch activities. This detail will determine how the Activity gets marked as completed.',
  ACTIVITY_COMPLETION_CRITERIA: 'Completion Criteria',
  ACTIVITY_COMPLETION_CRITERIA_HINT:
    'Condition that must be true for the Activity to be completed. All must be true, and any events above must be triggered.',
  ACTIVITY_LOOK_BACK_START_DATE: 'Look-back Start Date',
  ACTIVITY_LOOK_BACK_START_DATE_HINT:
    'Allows for this Activity to be marked as completed for users who have already met the conditions. Determines how far to look back in the past for the conditions that mark this Activity as completed.',
  ACTIVITY_LOOK_BACK_END_DATE: 'Look-back End Date',
  ACTIVITY_LOOK_BACK_END_DATE_HINT:
    'Determines when to stop looking back for the conditions that mark this Activity as completed.',
  ACTIVITY_VISIBILITY_END_DATE: 'Expire by Visibility End Date',
  ACTIVITY_EVENT_ID: 'Event ID',
  ACTIVITY_EVENT_ID_HINT:
    'Identifiers for the events that mark this Activity as complete. All must be triggered.',
  ACTIVITY_CAMPAIGN_TYPE_TRIGGER: 'Campaign Type of Completion Trigger',
  ACTIVITY_CAMPAIGN_TYPE_TRIGGER_HINT:
    'This field is used to specify the type of campaign that triggers the no-touch activity.',
  ACTIVITY_CAMPAIGN_TYPE_TRIGGER_PLACEHOLDER: 'Please Select a Campaign Type',
  ACTIVITY_OPT_IN: 'Opt-In',
  ACTIVITY_TRIGGER_CAMPAIGN_ID: 'Trigger Campaign ID',
  ACTIVITY_TRIGGER_CAMPAIGN_ID_HINT:
    'If there is a specific Campaign that triggers the No-touch completion criteria, link to it here.',
  /* REWARDS DATA SECTION */
  ACTIVITY_REWARDS_DATA: 'Rewards',
  ACTIVITY_POINTS: 'Awarded Currency on Completion',
  ACTIVITY_REWARD_CRITERIA: 'Reward Criteria',
  ACTIVITY_REWARD_CRITERIA_HINT:
    'Determines what conditions need to be true in order to receive rewards.',
  ACTIVITY_UNIT: 'Unit of Measure for Reward Currency',
  ACTIVITY_BADGE: 'Reward Currency Icon',
  ACTIVITY_COMPONENT_TYPE: 'Component Type',
  /* RICH TEXT COMPONENT */
  ACTIVITY_RICH_TEXT_COMPONENT: 'Rich Text',
  ACTIVITY_RICH_TEXT_COMPONENT_ATTRIBUTES: 'Rich Text Properties',
  ACTIVITY_RICH_TEXT: 'Rich Text',
  ACTIVITY_RICH_TEXT_HINT:
    'This field adds HTML tags to add formatting to your text.',
  /* IMAGE COMPONENT */
  ACTIVITY_IMAGE_COMPONENT: 'Image',
  ACTIVITY_IMAGE: 'Image',
  ACTIVITY_IMAGE_COMPONENT_ATTRIBUTES: 'Image Properties',
  ACTIVITY_IMAGE_URL: 'Image URL',
  ACTIVITY_IMAGE_URL_HINT: 'This represents an image asset from Contentful',
  ACTIVITY_IMAGE_ALT: 'Image Alt',
  ACTIVITY_IMAGE_ALT_HINT: 'Alternative text',
  ACTIVITY_IMAGE_CAPTION: 'Caption',
  ACTIVITY_IMAGE_CAPTION_HINT: 'Describes the image',
  /* LINK COMPONENT */
  ACTIVITY_LINK_COMPONENT: 'Link',
  ACTIVITY_LINK_COMPONENT_HINT:
    'To link users to somewhere else in-app (deep-link) or to an external website (link out) while they doing an activity.',
  ACTIVITY_LINK: 'Link',
  ACTIVITY_LINK_COMPONENT_ATTRIBUTES: 'Link Properties',
  ACTIVITY_LINK_ICON_URL_HINT: 'This represents an image asset from Contentful',
  ACTIVITY_LINK_URL: 'URL',
  ACTIVITY_LINK_URL_HINT: 'The URL for the deep-link or link-out',
  ACTIVITY_LINK_TITLE: 'Title',
  ACTIVITY_LINK_TITLE_HINT: 'The text for the title below the image',
  /* BANNER COMPONENT */
  ACTIVITY_BANNER_COMPONENT: 'Banner',
  ACTIVITY_BANNER: 'Banner',
  ACTIVITY_BANNER_COMPONENT_ATTRIBUTES: 'Banner Properties',
  ACTIVITY_BANNER_TITLE: 'Title',
  ACTIVITY_BANNER_TITLE_HINT: 'Text that displays as the banner title',
  ACTIVITY_BANNER_ICON_URL_HINT:
    'The icon that shows up beside the banner title. This represents an image asset from Contentful.',
  ACTIVITY_BANNER_TYPE: 'Type',
  ACTIVITY_BANNER_TYPE_PRESENTATION_TITLE:
    'There are three types of banners you can choose from:',
  ACTIVITY_BANNER_TYPE_PRESENTATION_DESCRIPTION:
    'Primary Banner: Emphasizes any important information you want to stand out to a user, like tips or next steps. \nSecondary Banner: Emphasizes any information that isn’t important enough for a primary banner, but is of more importance that information in a subtle banner. Subtle Banner: Contains information that needs to be present for legal reasons, like a legal disclaimer. You can also include links in the banner, but this is optional.',
  ACTIVITY_BANNER_TYPE_PLACEHOLDER: 'Select Banner Type',
  ACTIVITY_BANNER_RICH_TEXT: 'Rich Text',
  ACTIVITY_BANNER_RICH_TEXT_HINT:
    'The text that displays in the body message of the banner. This can handle rich text.',
  /* BLOCKQUOTE COMPONENT */
  ACTIVITY_BLOCK_QUOTE_COMPONENT: 'Block-Quote',
  ACTIVITY_BLOCK_QUOTE: 'Block-Quote',
  ACTIVITY_BLOCK_QUOTE_COMPONENT_ATTRIBUTES: 'Block-quote Properties',
  ACTIVITY_BLOCK_QUOTE_TEXT: 'Text',
  ACTIVITY_BLOCK_QUOTE_TEXT_HINT:
    'This field should include the quote we want to display to users within the component',
  /* LIST COMPONENT */
  ACTIVITY_LIST_COMPONENT: 'List',
  ACTIVITY_LIST_COMPONENT_ATTRIBUTES: 'List Properties',
  ACTIVITY_LIST_COMPONENT_ATTRIBUTES_HINT:
    'Enables us to display a formatted list',
  ACTIVITY_LIST_ATTRIBUTE: 'List',
  ACTIVITY_LIST_ATTRIBUTE_HINT:
    'This field opens an array/collection of text that should display  on each line of the list.',
  ACTIVITY_LIST_ARRAY_ADD_LABEL: 'List item',
  /* HEADER COMPONENT */
  ACTIVITY_HEADER_COMPONENT: 'Header',
  ACTIVITY_HEADER: 'Header',
  ACTIVITY_HEADER_COMPONENT_ATTRIBUTES: 'Header Properties',
  ACTIVITY_HEADER_COMPONENT_ATTRIBUTES_HINT:
    'This is the intro image at the top of the first activity page. This includes a progress bar that tracks user progress of activity completion within a campaign/program.',
  ACTIVITY_HEADER_IMAGE_URL: 'Image URL',
  ACTIVITY_HEADER_IMAGE_URL_HINT:
    'This represents an image asset from Contentful',
  ACTIVITY_HEADER_PROGRESS_PERCENTAGE: 'Progress Percentage',
  ACTIVITY_HEADER_PROGRESS_PERCENTAGE_BANNER_TITLE: 'Progress Percentage Info',
  ACTIVITY_HEADER_PROGRESS_PERCENTAGE_BANNER_DESCRIPTION:
    'The progress won’t show up for campaigns with only one activity, because it wouldn’t be helpful. The progress won’t show up for suggested campaigns, because the member never sees the campaign itself, so won’t be able to connect one activity with the next.',
  ACTIVITY_HEADER_CAMPAIGN_NAME: 'Campaign Name',
  ACTIVITY_HEADER_ALT: 'Alt Text',
  /* REWARD HEADER COMPONENT */
  ACTIVITY_REWARDS_HEADER_COMPONENT: 'Reward Header',
  ACTIVITY_REWARDS_HEADER: 'Rewards Header',
  ACTIVITY_REWARDS_HEADER_COMPONENT_ATTRIBUTES: 'Rewards Header Properties',
  ACTIVITY_REWARDS_HEADER_COMPONENT_ATTRIBUTES_HINT:
    'This version of the header component is rendered without a circular progress tracker and is best used in cases when it doesn’t make sense to track your progress (example: for standalone activities with only 1 activity associated).',
  /* COMPLETION COUNTER COMPONENT */
  ACTIVITY_COMPLETION_COUNTER_COMPONENT: 'Completion Counter',
  ACTIVITY_COMPLETION_COUNTER: 'Completion Counter',
  ACTIVITY_COMPLETION_COUNTER_COMPONENT_ATTRIBUTES:
    'Completion Counter Properties',
  ACTIVITY_COMPLETION_COUNTER_UNIT: 'Unit',
  ACTIVITY_COMPLETION_COUNTER_GOAL: 'Goal',
  ACTIVITY_COMPLETION_COUNTER_INPUT_METHODS: 'Input Methods',
  ACTIVITY_COMPLETION_COUNTER_ARRAY_ADD_LABEL: 'Input method',
  ACTIVITY_COMPLETION_COUNTER_INPUT_METHODS_PLACEHOLDER:
    'Choose an input method',
  /* INTEGER INPUT COMPONENT */
  ACTIVITY_INTEGER_INPUT_COMPONENT: 'Integer Input',
  ACTIVITY_INTEGER_INPUT: 'Integer Input',
  ACTIVITY_INTEGER_INPUT_INPUT_METHODS_PLACEHOLDER:
    'Choose an integer input component',
  ACTIVITY_INTEGER_INPUT_COMPONENT_ATTRIBUTES: 'Integer Input Properties',
  ACTIVITY_INPUT_PREFIX: 'Prefix',
  ACTIVITY_INPUT_PREFIX_HINT: 'A text that is displayed before the input',
  ACTIVITY_INPUT_SUFFIX: 'Suffix',
  ACTIVITY_INPUT_SUFFIX_HINT:
    'A text that is displayed after the input. Ex. inches',
  ACTIVITY_INPUT_PRE_POPULATE: 'Prepopulate',
  ACTIVITY_INPUT_INPUT_METHOD: 'Input Method',
  ACTIVITY_INTEGER_INPUT_INPUT_METHOD_HINT:
    'The only available InputMethod is "manual", where the user manually types the answer',
  ACTIVITY_INPUT_ALT_TEXT_HINT:
    'A descriptive for Accessibility text that describes what is being presented to the user.',
  ACTIVITY_INPUT_PLACEHOLDER: 'Placeholder',
  ACTIVITY_INPUT_PLACEHOLDER_HINT:
    'A text field to be displayed as placeholder inside the input. ex. Enter your answer here.',
  ACTIVITY_INPUT_VALIDATION: 'Validation',
  ACTIVITY_INPUT_VALIDATION_MIN_VALUE: 'Minimum Value',
  ACTIVITY_INPUT_VALIDATION_MIN_VALUE_HINT:
    'The lowest value the user can input',
  ACTIVITY_INPUT_VALIDATION_MAX_VALUE: 'Maximum Value',
  ACTIVITY_INPUT_VALIDATION_MAX_VALUE_HINT:
    'The highest value the user can input',
  ACTIVITY_INPUT_VALIDATION_REQUIRED_HINT:
    'If true, the user must answer that question before moving to the next page',
  ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING: 'Custom Warning',
  ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING_HINT:
    'A custom error message for this component',
  ACTIVITY_INPUT_VALIDATION_EDITABLE: 'Editable',
  ACTIVITY_INPUT_VALIDATION_EDITABLE_HINT:
    'Setting editable to "false" disables the component',
  /* DECIMAL INPUT COMPONENT */
  ACTIVITY_DECIMAL_INPUT_COMPONENT: 'Decimal Input',
  ACTIVITY_DECIMAL_INPUT: 'Decimal Input',
  ACTIVITY_DECIMAL_INPUT_COMPONENT_ATTRIBUTES: 'Decimal Input Properties',
  /* STRING INPUT COMPONENT */
  ACTIVITY_STRING_INPUT_COMPONENT: 'String Input',
  ACTIVITY_STRING_INPUT: 'String Input',
  ACTIVITY_STRING_INPUT_COMPONENT_ATTRIBUTES: 'String Input Properties',
  ACTIVITY_STRING_CHARACTER_LIMIT: 'Character Limit',
  ACTIVITY_STRING_INPUT_INPUT_METHOD_HINT:
    'There are 2 inputMethod options: line (displays a text field), box: displays a text area. Both are for visual purposes only.',
  ACTIVITY_STRING_PLACEHOLDER: 'Choose an Input Method',
  /* FULL PAGE HIGHLIGHT COMPONENT */
  ACTIVITY_FULL_PAGE_HIGHLIGHT_COMPONENT: 'Full Page Highlight',
  ACTIVITY_FULL_PAGE_HIGHLIGHT: 'Full Page Highlight',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_COMPONENT_ATTRIBUTES:
    'Full Page Highlight Properties',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_TITLE: 'Title',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_TITLE_HINT: 'The text displayed in the title',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_SUBTITLE: 'Subtitle',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_SUBTITLE_HINT:
    'The text displayed in the subtitle',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_IMAGE: 'Highlight Image',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_IMAGE_HINT:
    'The image displayed above the tile',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_TEXT: 'Highlight Text',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_TEXT_HINT:
    'The text displayed in the body paragraph. Can be Rich Text.',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_BACKGROUND_IMAGE: 'Background Image',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_BACKGROUND_COLOR: 'Background Color',
  ACTIVITY_FULL_PAGE_HIGHLIGHT_BACKGROUND_COLOR_HINT:
    'Default is set to decorativeBrandSecondaryPastel. Needs to be a genesis color token type.',
  /* PROGRESS TRACKING COMPONENT */
  ACTIVITY_PROGRESS_TRACKING_COMPONENT: 'Progress Tracking',
  ACTIVITY_PROGRESS_TRACKING: 'Progress Tracking',
  ACTIVITY_PROGRESS_TRACKING_COMPONENT_ATTRIBUTES:
    'Progress Tracking Properties',
  ACTIVITY_PROGRESS_TRACKING_COMPONENT_ATTRIBUTES_HINT:
    'Allows users to see their progress on a trackable data type (i.e steps)',
  ACTIVITY_PROGRESS_TRACKING_BANNER_TITLE: 'Info',
  ACTIVITY_PROGRESS_TRACKING_BANNER_DESCRIPTION:
    'Users can: manually track/edit their progress OR automatically track from a connected device (i.e Fitbit).',
  ACTIVITY_PROGRESS_TRACKING_UNIT: 'Unit',
  ACTIVITY_PROGRESS_TRACKING_UNIT_HINT:
    'User-facing data describing the expected action from the user. Ex.: Steps',
  ACTIVITY_PROGRESS_TRACKING_GOAL: 'Goal',
  ACTIVITY_PROGRESS_TRACKING_GOAL_HINT:
    'The amount of units the user needs to accumulate for that given component.',
  ACTIVITY_PROGRESS_TRACKING_INPUT_METHOD: 'Input Method',
  ACTIVITY_PROGRESS_TRACKING_INPUT_METHOD_HINT:
    'Defines how the user can see and interact with the component. Only supported option is: circular-bar',
  ACTIVITY_PROGRESS_TRACKING_INPUT_METHOD_PLACEHOLDER:
    'Choose an input method type',
  ACTIVITY_PROGRESS_TRACKING_CIRCULAR_BAR: 'Circular bar',
  ACTIVITY_PROGRESS_TRACKING_HORIZONTAL_BAR: 'Horizontal bar',
  ACTIVITY_PROGRESS_TRACKING_BUTTON: 'Button',
  ACTIVITY_PROGRESS_TRACKING_CURRENT_PROGRESS_LIMIT: 'Current Progress Limit',
  ACTIVITY_PROGRESS_TRACKING_CURRENT_PROGRESS_LIMIT_HINT:
    'The largest amount of progress a user can achieve',
  ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS: 'Update Window In Days',
  ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS_HINT:
    'An offset number of days used to calculate if the component is editable.',
  ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS_BANNER_TITLE: 'Info',
  ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS_BANNER_DESCRIPTION:
    'To define if the component is editable (regardless of activity status), the current date minus the number of days in the updateWindow must be less or equal the activity end date. In cases where updateWindowInDays is not set for the component, the editable value will be set according to the activity status (true if Active and false for any other status).',
  ACTIVITY_PROGRESS_TRACKING_EDIT_BUTTON_LABEL: 'Edit Button Label',
  ACTIVITY_PROGRESS_TRACKING_EDIT_BUTTON_LABEL_HINT:
    'Defines the text shown for the edit button. Ex.: Edit Steps',
  /* BADGE COMPONENT */
  ACTIVITY_BADGE_COMPONENT: 'Badge',
  ACTIVITY_BADGE_COMPONENT_ATTRIBUTES: 'Badge Properties',
  ACTIVITY_BADGE_TEXT: 'Text',
  ACTIVITY_BADGE_TEXT_HINT: 'The text displayed within the badge',
  ACTIVITY_BADGE_ALT_TEXT_HINT:
    'This describes the text that screen-readers would read for accessibility',
  ACTIVITY_BADGE_ICON_URL_HINT:
    'An URL for an image to be shown at the left of the text.',
  ACTIVITY_BADGE_STYLE_MODIFIERS: 'Style Modifiers',
  ACTIVITY_BADGE_STYLE_MODIFIERS_HINT:
    'A group of properties that can affect the appearance of the component',
  ACTIVITY_BADGE_STYLE_MODIFIERS_HORIZONTAL_ALIGNMENT_HINT:
    'There are 3 options: start (left align), end (right align) center (default)',
  ACTIVITY_BADGE_STYLE_MODIFIERS_PLACEHOLDER: 'Please choose a style',
  ACTIVITY_BADGE_STYLE_MODIFIERS_BORDER_RADIUS_HINT:
    'Enter “pill” to render the pill shape associated with this badge',
  ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_HINT:
    'This background color represents the badge background',
  ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_OPTION_1:
    'decorative-brand-primary-pastel',
  ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_OPTION_2:
    'decorative-brand-secondary-pastel',
  ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_OPTION_3:
    'decorative-brand-tertiary-pastel',
  ACTIVITY_BADGE_PLACEHOLDER_MODIFIER: 'Activity Badge Modifier',
  ACTIVITY_BADGE_DATE_FORMATTER: 'Activity Badge Date Format',
  ACTIVITY_BADGE_DATE_FORMATTER_HINT:
    'This sets the format with which we render the date in the activity badge if a placeholder is used in text field. For example: <ul><li>Full: Sunday, July 7, 2024</li> <li>Long: July 7, 2024</li> <li>Medium: Jul 7, 2024</li><li>Short: 7/7/24.</li></ul>',
  ACTIVITY_BADGE_COMPONENT_STORAGE_SOURCES_HINT:
    'Specify the observation code for the FHIR Observation storage source',
  /* CHALLENGE PROGRESS COMPONENT */
  ACTIVITY_CHALLENGE_PROGRESS_COMPONENT: 'Challenge Progress',
  ACTIVITY_CHALLENGE_PROGRESS: 'Challenge Progress',
  ACTIVITY_CHALLENGE_PROGRESS_COMPONENT_ATTRIBUTES:
    'Challenge Progress Properties',
  ACTIVITY_CHALLENGE_PROGRESS_TITLE_HINT: 'The challenge title',
  ACTIVITY_CHALLENGE_PROGRESS_UNIT_HINT:
    'User-facing data describing the expected action from the user (e.g. Days)',
  ACTIVITY_CHALLENGE_PROGRESS_GOAL_HINT:
    'The amount of challenges the user must complete to complete the given activity',
  ACTIVITY_CHALLENGE_PROGRESS_START_DATE_HINT:
    'The Start Date of the Challenge/Campaign',
  ACTIVITY_CHALLENGE_PROGRESS_END_DATE_HINT:
    'The End Date of the Challenge/Campaign',
  ACTIVITY_CHALLENGE_PROGRESS_DEEP_LINK: 'Deep Link',
  ACTIVITY_CHALLENGE_PROGRESS_DEEP_LINK_HINT:
    'A deep link that directs the user to the challenge page.',
  /* BIOMETRIC COMPONENT */
  ACTIVITY_BIOMETRIC_COMPONENT: 'Biometric',
  ACTIVITY_BIOMETRIC: 'Biometric',
  ACTIVITY_BIOMETRIC_COMPONENT_ATTRIBUTES: 'Biometric Component Properties',
  ACTIVITY_BIOMETRIC_COLLECTED_ON: 'Collected On',
  ACTIVITY_BIOMETRIC_STATUS_TEXT: 'Status Text',
  ACTIVITY_BIOMETRIC_STATUS_PLACEHOLDER: 'Please Select a Status',
  ACTIVITY_BIOMETRIC_STATUS_IN_RANGE: 'In Range',
  ACTIVITY_BIOMETRIC_STATUS_OUT_OF_RANGE: 'Out of Range',
  ACTIVITY_BIOMETRIC_STATUS_INCONCLUSIVE: 'Inconclusive',
  ACTIVITY_BIOMETRIC_STATUS_NO_DATA_AVAILABLE: 'No Data Available',
  /* MULTISELECT INPUT COMPONENT */
  ACTIVITY_MULTI_SELECT_INPUT_COMPONENT: 'Multiple Select Input',
  ACTIVITY_MULTI_SELECT_INPUT: 'Multiple Select Input',
  ACTIVITY_MULTI_SELECT_INPUT_ATTRIBUTES: 'Multiple Select Input Properties',
  ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS: 'Answer Options',
  ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS_HINT:
    'Available options the user can select as an answer',
  ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS_ADD_LABEL: 'Answer Option',
  ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS_ITEM: 'Answer Option Item',
  ACTIVITY_MULTI_SELECT_INPUT_TEXT_HINT:
    'The main text representing the actual answer the user wants to select',
  ACTIVITY_MULTI_SELECT_INPUT_SUB_TEXT_HINT:
    'An optional description of the answer the user is selecting',
  ACTIVITY_MULTI_SELECT_INPUT_ENABLE_FREE_TEXT: 'Enable Free Text',
  ACTIVITY_MULTI_SELECT_INPUT_ENABLE_FREE_TEXT_HINT:
    'If this attribute is true, a text input field will be available for the user to manually type in their own answer',
  ACTIVITY_MULTI_SELECT_INPUT_VALUE_HINT:
    'The value being stored in FHIR QuestionnaireResponse as the actual user response. Its data is not displayed to the user (except on Free Text Answer Options).',
  ACTIVITY_MULTI_SELECT_INPUT_VALUE_BANNER_DESCRIPTION:
    'The value data can be: a Loinc Code, a League Custom Code, or if enableFreeText is true, the text entered by the user will be the value.',
  ACTIVITY_MULTI_SELECT_INPUT_TYPE: 'Input Type',
  ACTIVITY_MULTI_SELECT_PLACEHOLDER: 'Choose an Input Type',
  ACTIVITY_MULTI_SELECT_EXCLUSIVE: 'Exclusive',
  ACTIVITY_MULTI_SELECT_EXCLUSIVE_HINT:
    'If this field is set to true, then the FE will unselect all other selected options if this option is selected',
  ACTIVITY_MULTI_SELECT_CHARACTER_LIMIT: 'Character Limit',
  ACTIVITY_MULTI_SELECT_CHARACTER_LIMIT_HINT:
    'To be populated when InputType is string',
  ACTIVITY_MULTI_SELECT_MIN_VALUE: 'Minimum Value',
  ACTIVITY_MULTI_SELECT_VALUE_HINT: 'To be populated when InputType is integer',
  ACTIVITY_MULTI_SELECT_MAX_VALUE: 'Maximum Value',
  ACTIVITY_MULTI_SELECT_MIN_SELECTION_REQUIRED: 'Minimum Selection Required',
  ACTIVITY_MULTI_SELECT_MIN_SELECTION_REQUIRED_HINT:
    'The minimum amount of AnswerOptions the user can select',
  ACTIVITY_MULTI_SELECT_MAX_SELECTION_REQUIRED: 'Maximum Selection Required',
  ACTIVITY_MULTI_SELECT_MAX_SELECTION_REQUIRED_HINT:
    'The maximum amount of AnswerOptions the user can select',
  ACTIVITY_INPUT_FREE_TEXT_VALIDATION: 'Free Text Validation',
  /* DATE INPUT COMPONENT */
  ACTIVITY_DATE_INPUT_COMPONENT: 'Date Input',
  ACTIVITY_DATE_INPUT: 'Date Input',
  ACTIVITY_DATE_INPUT_COMPONENT_ATTRIBUTES: 'Date Input Properties',
  ACTIVITY_DATE_INPUT_DATE_INPUT_METHOD: 'Date Input Method',
  ACTIVITY_DATE_INPUT_METHOD_DATE_PICK: 'Date Pick',
  ACTIVITY_DATE_INPUT_METHOD_MONTH_YEAR: 'Month Year',
  ACTIVITY_DATE_INPUT_METHOD_PLACEHOLDER: 'Choose a date input method',
  ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS: 'Additional Date Options',
  ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_HINT:
    'Contains pre-determined date options the user can select as an answer.',
  ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_ADD_LABEL: 'Date option',
  ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_ARRAY_ITEM: 'Date option',
  ACTIVITY_DATE_INPUT_MIN_DATE: 'Minimum Date',
  ACTIVITY_DATE_INPUT_MAX_DATE: 'Maximum Date',
  ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_VALUE_HINT:
    'Any number including negatives. Eg. -3, 4, etc.',
  ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_UNIT_HINT:
    'The unit in which you want to measure this component by. Ex. months',
  /* PERSON HEIGHT/WEIGHT INPUT COMPONENT */
  ACTIVITY_PERSON_HEIGHT_INPUT_COMPONENT: 'Person Height',
  ACTIVITY_PERSON_WEIGHT_INPUT_COMPONENT: 'Person Weight',
  ACTIVITY_PERSON_HEIGHT_INPUT: 'Person Height Input',
  ACTIVITY_PERSON_WEIGHT_INPUT: 'Person Weight Input',
  ACTIVITY_PERSON_HEIGHT_INPUT_COMPONENT_ATTRIBUTES:
    'Person Height Input Properties',
  ACTIVITY_PERSON_WEIGHT_INPUT_COMPONENT_ATTRIBUTES:
    'Person Weight Input Properties',
  ACTIVITY_PERSON_HEIGHT_INPUT_UNIT_HINT:
    'Identifies what Unit of Measure the user answer refers to and the unit for the users response to be sent. Eg. inches or cm',
  ACTIVITY_PERSON_WEIGHT_INPUT_UNIT_HINT:
    'Identifies what Unit of Measure the user answer refers to and the unit for the users response to be sent. Eg. inches or cm',
  /* COMPONENT STORAGE SOURCES */
  ACTIVITY_COMPONENT_STORAGE_SOURCES: 'Component Storage Sources',
  ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT:
    'A determination of how the answer should be stored.',
  ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES:
    'Questionnaire Storage Source Properties',
  ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES:
    'Observation Storage Source Properties',
  ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES:
    'Care Plan Storage Source Properties',
  ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES:
    'Consent Storage Source Properties',
  ACTIVITY_STORAGE_SOURCE_TYPE: 'Storage Source Type',
  ACTIVITY_STORAGE_SOURCE_ATTRIBUTES: 'Storage Source Properties',
  ACTIVITY_FHIR_QUESTIONNAIRE: 'FHIR Questionnaire',
  ACTIVITY_FHIR_OBSERVATION: 'FHIR Observation',
  ACTIVITY_FHIR_CARE_PLAN: 'FHIR Care Plan',
  ACTIVITY_CONSENT: 'Consent',
  ACTIVITY_CONSENT_STORE_PLACEHOLDER: 'Choose a consent store',
  ACTIVITY_QUESTIONNAIRE_REFERENCE: 'Questionnaire Reference',
  ACTIVITY_LINK_ID: 'Link ID',
  ACTIVITY_CARE_PLAN_IDS: 'Care Plan IDs',
  ACTIVITY_CARE_PLAN_ADD_LABEL: 'Care plan id',
  ACTIVITY_CONSENT_TYPE: 'Consent Type',
  ACTIVITY_CONSENT_ENTITY: 'Consent Entity',
  ACTIVITY_CONSENT_STORE: 'Consent Store',
  /* Rules template names */
  COMPLETE_ANY_CAMPAIGN: 'Complete any campaign',
  COMPLETE_ANY_CAMPAIGN_BY_TYPE: 'Complete any campaign by type',
  COMPLETE_SPECIFIC_CAMPAIGN_OR_CHALLENGE:
    'Complete a specific campaign or challenge',
  COMPLETE_CAMPAIGN_MEET_REWARD_POINT_THRESHOLD:
    'Complete Campaign based on meeting Reward point threshold',
  ACTIVITY_COMPLETE_REWARD_GOAL_SET_FOR_CHALLENGE:
    'Complete the reward goal set for a Challenge',
  ACTIVITY_COMPLETE_X_ACTIVITIES_FOR_SPECIFIC_CAMPAIGN_OR_CHALLENGE:
    'Complete X number of activities for a specific Campaign or Challenge',
  ACTIVITY_REWARD_POINTS_BIOMETRIC_OBSERVATION_VALUE:
    'Reward points based on the value of a biometric observation',
  ACTIVITIES_AWARD_POINTS_MAX_POINTS_THRESHOLD:
    'Award points based on max points threshold',
  /* VIDEO COMPONENT */
  ACTIVITY_VIDEO: 'Video',
  ACTIVITY_VIDEO_ALT_TEXT_HINT:
    "Briefly describe the video content. This text will be displayed for users of assistive technology, and should consider the what is important to convey to someone who cannot see the video so they can understand it's purpose.",
  ACTIVITY_VIDEO_ALT_TEXT: 'Video Alt Text',
  ACTIVITY_VIDEO_COMPONENT_ATTRIBUTES: 'Video Properties',
  ACTIVITY_VIDEO_COMPONENT_HINT: 'Embed a video inside an activity.',
  ACTIVITY_VIDEO_COMPONENT: 'Video',
  ACTIVITY_VIDEO_SUBTITLES_ADD_LABEL: 'Add Caption/subtitle',
  ACTIVITY_VIDEO_SUBTITLES_ARRAY_ITEM: 'Caption/Subtitle',
  ACTIVITY_VIDEO_SUBTITLES_HINT:
    'Captions provide content to people who are Deaf and hard-of-hearing, or those who process written information better than audio. These are a text version of the speech and non-speech audio information needed to understand the content. Captions are formatted in such a way that they are synchronized with the audio from the video. Intra-lingual subtitles could also be provided if subtitles in another locale are required.',
  ACTIVITY_VIDEO_SUBTITLES_LABEL_HINT:
    'This should represent which language the caption or subtitle file is in. This will display in the dropdown of caption options when more than 1 language is available.',
  ACTIVITY_VIDEO_SUBTITLES_LABEL: 'Caption or Subtitle Language Name',
  ACTIVITY_VIDEO_SUBTITLES_URL_HINT:
    'Please provide the url of where you .vtt caption or subtitle file is stored for this language.',
  ACTIVITY_VIDEO_SUBTITLES_URL: 'Caption or Subtitle file URL',
  ACTIVITY_VIDEO_SUBTITLES: 'Captions/subtitles',
  ACTIVITY_VIDEO_THUMBNAIL_URL_HINT:
    'This is the static cover image of your video that displays when the video is not playing and the page has loaded.',
  ACTIVITY_VIDEO_THUMBNAIL_URL: 'Video Thumbnail URL',
  ACTIVITY_VIDEO_TRANSCRIPT_HINT:
    'It’s best practice to supply a descriptive transcript for your video. A descriptive transcript includes a text version of the speech and non-speech audio information someone may need to understand the content. Additionally, descriptive transcripts include a text description of the visual information needed to understand the content.',
  ACTIVITY_VIDEO_TRANSCRIPT: 'Video Transcript',
  ACTIVITY_VIDEO_VIDEO_URL_HINT:
    'Provide the link for where you video content is hosted. This link should end with a .mp4 file type.',
  ACTIVITY_VIDEO_VIDEO_URL: 'Video URL',
};
