import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BasePageLayout, EmptyStateCard } from '@web-config-app/core-react-ui';
import { useConfigAppParams } from '@web-config-app/core-react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { AppHeaderContainer } from '../../containers/app-header-container/app-header.container';
import { DomainNavContainer } from '../../containers/domain-nav-container/domain-nav.container';

// TODO :: ErrorFallback to be generic and moved with https://everlong.atlassian.net/browse/CACT-925
const ErrorFallback = ({ error }: { error: Error }) => (
  <StackLayout horizontalAlignment="stretch" css={{ padding: '$twoAndHalf' }}>
    <EmptyStateCard heading="Error" body={error.message} />
  </StackLayout>
);

export const BasePage: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const { environmentId } = useConfigAppParams();
  return (
    <BasePageLayout
      topBar={<AppHeaderContainer currentEnvironment={environmentId} />}
      sidePanel={<DomainNavContainer />}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </BasePageLayout>
  );
};
