import React from 'react';
import { Box, Tooltip } from '@leagueplatform/genesis-core';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuItem } from '../../../types/components.types';
import { dropdownMenuItemStyles } from '../select-action-menu.styles';

interface SelectActionMenuItemProps {
  item: MenuItem;
  setDropdownOpen: (value: boolean) => void;
  handleModalOpenChange: (item: MenuItem) => void;
}

/**
 * Renders a single menu item (radix DropdownMenu.Item) within the SelectActionMenu component.
 *
 * If the item has a `disabledTooltip`, the item's action will not be fired on click and instead disabled styles and a tooltip explaining why the item is disabled will appear on hover/focus.
 * If the item has a `confirmationModal` property, clicking it will trigger the `handleModalOpenChange` function.
 * Otherwise, it will execute the `action` function associated with the item.
 *
 * @param {MenuItem} item - The menu item configuration object.
 * @param {Function} setDropdownOpen - A function to set the open state of the dropdown.
 * @param {Function} handleModalOpenChange - A function to handle opening a confirmation modal.
 *
 * @returns {JSX.Element} The rendered menu item component.
 */

export const SelectActionMenuItem = ({
  item,
  setDropdownOpen,
  handleModalOpenChange,
}: SelectActionMenuItemProps) => {
  const { disabledTooltip, action, label, confirmationModal } = item;
  return disabledTooltip ? (
    <Tooltip
      align="center"
      avoidCollisions
      content={disabledTooltip}
      side="left"
      sideOffset={30}
    >
      <Box as={DropdownMenu.Item} css={dropdownMenuItemStyles}>
        {label}
      </Box>
    </Tooltip>
  ) : (
    <Box
      as={DropdownMenu.Item}
      css={dropdownMenuItemStyles}
      onSelect={() => {
        if (confirmationModal) {
          setDropdownOpen(false);
          handleModalOpenChange(item);
        } else {
          setDropdownOpen(false);
          action?.();
        }
      }}
    >
      {label}
    </Box>
  );
};
