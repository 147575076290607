import type {
  ComputedEndpointParam,
  EndpointParameterValue,
} from '@web-config-app/core';

const isEndpointParameterValue = (
  value: ComputedEndpointParam,
): value is EndpointParameterValue => value !== null;

export const getEndpointParamsWithValues = (
  paramResults: Array<ComputedEndpointParam>,
) => paramResults.filter(isEndpointParameterValue);
