import * as React from 'react';
import type { Nullable, AnnotatedJsonSchema } from '@web-config-app/core';
import { getEntityInstanceName } from '@web-config-app/core';
import { PrimitiveObjectArrayItem } from './primitive-object-array-item.component';
import type { EntityReferenceControlSchema } from '../../../types/controls';
import { useReferencedEntityData } from '../../../hooks/use-referenced-entity-data/use-referenced-entity-data';
import { useEntityReferenceSchema } from '../../../hooks/use-entity-reference-schema/use-entity-reference-schema';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import type { PrimitiveObjectArrayItemProps } from '../../../types/components';

export interface PrimitiveObjectArrayItemWithEntityReferenceProps
  extends PrimitiveObjectArrayItemProps {
  entityReferencePropertySchema: Nullable<AnnotatedJsonSchema>;
  referenceInstanceId?: string;
}

/**
 * Special wrapper around the array of primitive object items when the item contains a property
 * that is an entity reference. This wrapper allows us to retrieve the entity's name and display it
 * as the item's label
 */

export const PrimitiveObjectArrayItemWithEntityReference = ({
  entityReferencePropertySchema: schema,
  referenceInstanceId: instanceId,
  ...props
}: PrimitiveObjectArrayItemWithEntityReferenceProps) => {
  const { itemLabel, handleRemoveItem, path, index } = props;

  const { entity, removeRelationshipItem } = useEntityReferenceSchema({
    schema: schema as EntityReferenceControlSchema,
  });

  const { currentAuthoringEnvironment } = useEntityDetailsProps();

  const { referencedEntity: entityReferenceData } = useReferencedEntityData({
    entity,
    instanceId,
    environment: currentAuthoringEnvironment,
  });

  const handleRemoveEntityReference = () => {
    handleRemoveItem(path, index)();

    if (entityReferenceData) {
      removeRelationshipItem(entityReferenceData.id);
    }
  };

  const itemLabelWithEntityTitle = entityReferenceData
    ? getEntityInstanceName(entityReferenceData, entity)
    : itemLabel;
  return (
    <PrimitiveObjectArrayItem
      {...props}
      handleRemoveItem={() => handleRemoveEntityReference}
      itemLabel={itemLabelWithEntityTitle}
    />
  );
};
