/* eslint-disable no-param-reassign */
import { createStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { set as _set } from 'lodash-es';
import type { EntityDetail, Entity } from '../../types/entities';

export type EntityDetailsDataStore = EntityDetailsDataStoreState &
  EntityDetailsDataStoreActions;

export interface EntityDetailsDataStoreState {
  entityData: Partial<EntityDetail> | undefined;
  included: Array<EntityDetail> | undefined;
}

export interface EntityDetailsDataStoreActions {
  reset: (entity?: Entity) => void;
  setIncludedEntities: (data: EntityDetail[] | undefined) => void;
  addIncludedEntity: (data: EntityDetail) => void;
  setEntityData: (data: any, path?: string) => void;
}

export const entityDetailsDataStore = createStore<EntityDetailsDataStore>()(
  subscribeWithSelector(
    immer((set) => ({
      entityData: undefined,
      included: undefined,
      setEntityData: (data, path) =>
        set((draft) => {
          if (typeof path === 'string' && path.trim().length > 0) {
            _set(draft.entityData ?? {}, path, data);
          } else {
            draft.entityData = data;
          }
        }),
      setIncludedEntities: (data) =>
        set((draft) => {
          draft.included = data;
        }),
      addIncludedEntity: (data) =>
        set((draft) => {
          if (!draft.included?.find((e: EntityDetail) => e.id === data.id))
            draft.included = [...(draft.included ?? []), data];
        }),
      reset: () =>
        set((draft) => {
          draft.entityData = undefined;
          draft.included = undefined;
        }),
    })),
  ),
);
