import { useCallback, useMemo } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import type {
  AuthoringEnvironmentClientInfo,
  EntityStatus,
  AuthoringEnvironmentId,
} from '@web-config-app/core';
import type {
  GetEntityDetailsPathForEnvAndId,
  GetEntityCreatePathForEnv,
} from '../use-config-app-path/use-config-app-path';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';
import type {
  MultiEnvironmentActions,
  OtherAuthoringEnvironmentState,
} from '../../types/multi-environment.types';

export const useEntityDetailsEnvironmentSelect = ({
  status,
  entityName,
  environmentId,
  entityInstanceId,
  getEntityDetailsPathForEnvAndId,
  getEntityCreatePathForEnv,
  otherAuthoringEnvironmentState,
  isEntitySaved,
}: {
  status: EntityStatus;
  entityName: string;
  environmentId: AuthoringEnvironmentId;
  entityInstanceId?: string;
  otherAuthoringEnvironmentState?: OtherAuthoringEnvironmentState;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  getEntityCreatePathForEnv: GetEntityCreatePathForEnv;
  isEntitySaved: boolean;
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const {
    currentAuthoringEnvironment,
    environmentMap,
    multiEnvironmentAuthoringEnabled,
  } = useAuthoringEnvironments({ environmentId });

  const switchEnvironment = useCallback(
    (environment: AuthoringEnvironmentClientInfo) => () => {
      history.push(
        entityInstanceId
          ? getEntityDetailsPathForEnvAndId(entityInstanceId, environment.id)
          : getEntityCreatePathForEnv(environment.id),
      );
    },
    [
      history,
      entityInstanceId,
      getEntityDetailsPathForEnvAndId,
      getEntityCreatePathForEnv,
    ],
  );

  const otherAuthoringEnvironmentEntityStatus =
    otherAuthoringEnvironmentState?.entityStatus;

  const copyToEnvironmentAction =
    otherAuthoringEnvironmentState?.copyToEnvironment;

  const availableEnvironments = useMemo(() => {
    if (!(environmentMap instanceof Map)) {
      return null;
    }
    const environments = Array.from(environmentMap.values());
    return (
      environments
        .map((environment) => ({
          id: environment.id,
          label: formatMessage({ id: environment.nameKey }),
          status:
            environment.id === currentAuthoringEnvironment
              ? status
              : otherAuthoringEnvironmentEntityStatus,
          // does not need an action if we are already on the environment selected, undefined to prevent re-render of form
          action:
            environment.id !== currentAuthoringEnvironment
              ? switchEnvironment(environment)
              : undefined,
          showConfirmationModal:
            environment.id !== currentAuthoringEnvironment
              ? !isEntitySaved
              : false,
        }))
        // filtering out if the environment does not have a status
        .filter((environment) => environment.status)
    );
  }, [
    environmentMap,
    formatMessage,
    currentAuthoringEnvironment,
    status,
    otherAuthoringEnvironmentEntityStatus,
    switchEnvironment,
    isEntitySaved,
  ]);

  // currently the only multi environment action is to copy to the other environment if it doesn't exist yet!
  const multiEnvironmentActions: MultiEnvironmentActions | undefined =
    useMemo(() => {
      if (
        !(environmentMap instanceof Map) ||
        otherAuthoringEnvironmentEntityStatus ||
        !otherAuthoringEnvironmentState
      ) {
        return undefined;
      }

      const otherEnvironment = environmentMap.get(
        otherAuthoringEnvironmentState.environmentId,
      );

      if (!otherEnvironment) {
        return undefined;
      }

      const otherEnvironmentName = formatMessage({
        id: otherEnvironment.nameKey,
      });

      return [
        [
          {
            label: formatMessage(
              { id: 'COPY_TO_ENVIRONMENT_ACTION' },
              { environment: otherEnvironmentName },
            ),
            id: `copy-to-${otherEnvironment.id}`,
            action: copyToEnvironmentAction,
            disabledTooltip: !isEntitySaved
              ? formatMessage(
                  { id: 'COPY_TO_ENVIRONMENT_UNSAVED_CHANGES' },
                  { entity: entityName },
                )
              : undefined,
          },
        ],
      ];
    }, [
      environmentMap,
      otherAuthoringEnvironmentEntityStatus,
      otherAuthoringEnvironmentState,
      formatMessage,
      copyToEnvironmentAction,
      isEntitySaved,
      entityName,
    ]);

  return {
    currentEnvironmentId: currentAuthoringEnvironment,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
    multiEnvironmentActions,
  };
};
