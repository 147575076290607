import {
  EndpointResponse,
  EndpointFetch,
  EntityConfigEndpoint,
} from '@web-config-app/core';
import { createEndpointFetch } from '@web-config-app/api';

export type GetEntityEndpoint<T extends EndpointResponse> = {
  endpointFetch: EndpointFetch<T>;
};

/**
 * Takes an {@link EntityConfigEndpoint} and returns an object with a `fetch` property
 * that calls the endpoint with optional path, body, and params arguments using {@link leagueFetch}.
 *
 * This is a low-level hook we use to compose more feature-specific hooks like {@link useEntityList} and
 * in intended as a general abstraction of `leagueFetch` to be used for all requests made to any entity's
 * endpoints.
 */

export const getEntityEndpoint = <ResponseType extends EndpointResponse>(
  endpoint: EntityConfigEndpoint,
  fetchFn: (path: string, options: any) => Promise<Response>,
): GetEntityEndpoint<ResponseType> => {
  const endpointFetch = createEndpointFetch<ResponseType>(endpoint, fetchFn);

  return { endpointFetch };
};
