import React, { createContext, useRef } from 'react';
import { entityDetailsDataStore } from '@web-config-app/core';

export const EntityDetailsDataStoreContext = createContext<
  typeof entityDetailsDataStore | null
>(null);

export function EntityDetailsDataStoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  /**
   * create a stable reference to the store using useRef, ensuring the store is only created once
   */
  const store = useRef(entityDetailsDataStore);

  return (
    <EntityDetailsDataStoreContext.Provider value={store.current}>
      {children}
    </EntityDetailsDataStoreContext.Provider>
  );
}
