import type { AuthoringEnvironmentId } from '@web-config-app/core';

interface ConfigAppPathParams {
  domainId?: string;
  entityId?: string;
  environmentId?: string;
}

export type GetEntityDetailsPathForId = (instanceId: string) => string;
export type GetEntityCreatePathForEnv = (
  envId: AuthoringEnvironmentId,
) => string;
export type GetEntityDetailsPathForEnvAndId = (
  instanceId: string,
  envId: AuthoringEnvironmentId,
) => string;
/**
 * Returns a function named `generateConfigPaths` that generates dynamic paths used in the config app.
 *
 * `generateConfigPaths` accepts parameters for any of the variable route ids.
 */
export const useConfigAppPath = () => {
  /**
   * The `generateConfigPaths` function generates the dynamic paths.
   *
   * @param {string} [params.domainId] - The domain ID.
   * @param {string} [params.entityId] - The entity ID.
   * @param {string} [params.environmentId] - The environment ID.
   * @returns {Object} An object containing the generated paths.
   */
  const generateConfigPaths = ({
    domainId,
    entityId,
    environmentId,
  }: ConfigAppPathParams = {}) => ({
    domainPath: `/domains/${domainId}`,
    entityListPath: `/domains/${domainId}/entities/${entityId}/environments/${environmentId}/list`,
    entityCreatePath: `/domains/${domainId}/entities/${entityId}/environments/${environmentId}/create`,
    getEntityDetailsPathForId: (instanceId: string) =>
      `/domains/${domainId}/entities/${entityId}/environments/${environmentId}/details/${instanceId}`,
    getEntityCreatePathForEnv: (envId: AuthoringEnvironmentId) =>
      `/domains/${domainId}/entities/${entityId}/environments/${envId}/create`,
    getEntityDetailsPathForEnvAndId: (
      instanceId: string,
      envId: AuthoringEnvironmentId,
    ) =>
      `/domains/${domainId}/entities/${entityId}/environments/${envId}/details/${instanceId}`,
  });
  return generateConfigPaths;
};
