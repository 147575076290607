import { composePaths } from '@jsonforms/core';

export const getComposedSchemaPropertyPath = (
  formPath: string | undefined,
  propertyPath: string,
) => {
  const basePath =
    !formPath || formPath === 'root'
      ? 'attributes'
      : composePaths('attributes', formPath);

  return composePaths(basePath, propertyPath);
};
