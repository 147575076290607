export const dropdownMenuTriggerStyles = {
  borderRadius: '$inputField',
  marginLeft: '$half',
  '&:hover': {
    borderColor: '$inputBorderHovered',
    backgroundColor: '$inputBackgroundHovered',
    color: '$tertiaryTextDefault',
  },
  '&:active': {
    borderColor: '$inputBorderPressed',
    backgroundColor: '$inputBackgroundPressed',
    color: '$tertiaryTextDefault',
  },
};

export const dropdownMenuContentStyles = {
  marginTop: '$quarter',
  backgroundColor: '$surfaceBackgroundPrimary',
  borderRadius: '$extraLarge',
  border: '$borderWidths$thin solid $interactiveBorderDefault',
};

export const dropdownMenuItemStyles = {
  borderBottom: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
  '&:first-child': {
    'border-top-left-radius': '$extraLarge',
    'border-top-right-radius': '$extraLarge',
  },
  '&:last-child': {
    borderBottom: 'none',
    'border-bottom-left-radius': '$extraLarge',
    'border-bottom-right-radius': '$extraLarge',
  },
};
