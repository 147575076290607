import * as React from 'react';
import type {
  Nullable,
  EntityRootData,
  EntityDetail,
  AuthoringEnvironmentId,
} from '@web-config-app/core';
import { UseEntityOperationsResult } from '@web-config-app/core-react';
import type {
  OtherAuthoringEnvironmentState,
  UseIncludedEntitiesResult,
} from '@web-config-app/core-react';
import {
  useEntityDetails,
  UseEntityDetailsProps,
  UseEntityDetailsResult,
} from '../../hooks/use-entity-details/use-entity-details';

export type EntityDetailsContextType = UseEntityDetailsResult & {
  operations: UseEntityOperationsResult;
  currentAuthoringEnvironment: AuthoringEnvironmentId;
  otherAuthoringEnvironmentState?: OtherAuthoringEnvironmentState;
  entityRootData: EntityRootData | undefined;
  setEntityRootData: (data: EntityDetail) => void;
  onTreeNodeSelect?: (args: any) => void;
  entityType: string;
  includedEntities?: EntityDetail[];
  addIncludedEntity?: (entity: EntityDetail) => void;
  setIncludedEntities?: (entities: EntityDetail[]) => void;
};

export type EntityDetailsContextResult = Nullable<EntityDetailsContextType>;

export const EntityDetailsContext =
  React.createContext<EntityDetailsContextResult>(null);

export interface Included {}

export interface EntityDetailsProviderProps
  extends Omit<UseEntityDetailsProps, 'data' | 'onDataChange'> {
  operations: UseEntityOperationsResult;
  currentAuthoringEnvironment: AuthoringEnvironmentId;
  otherAuthoringEnvironmentState?: OtherAuthoringEnvironmentState;
  entityType: string;
  included?: UseIncludedEntitiesResult;
}

export const EntityDetailsProvider = ({
  children,
  operations,
  entityRootData,
  setEntityRootData,
  currentAuthoringEnvironment,
  otherAuthoringEnvironmentState,
  rootSchema,
  onTreeNodeSelect,
  path,
  entityType,
  instanceId,
  entity,
  included,
}: React.PropsWithChildren<EntityDetailsProviderProps>) => {
  const entityDetailsValue = useEntityDetails({
    path,
    instanceId,
    rootSchema,
    setEntityRootData,
    entityRootData,
    onTreeNodeSelect,
    entity,
  });

  const entityDetailsCtxValue = React.useMemo(
    () => ({
      ...entityDetailsValue,
      ...included,
      entityType,
      operations,
      currentAuthoringEnvironment,
      otherAuthoringEnvironmentState,
      entityRootData,
      setEntityRootData,
    }),
    [
      entityDetailsValue,
      included,
      entityType,
      operations,
      currentAuthoringEnvironment,
      otherAuthoringEnvironmentState,
      entityRootData,
      setEntityRootData,
    ],
  );

  return (
    <EntityDetailsContext.Provider value={entityDetailsCtxValue}>
      {children}
    </EntityDetailsContext.Provider>
  );
};
