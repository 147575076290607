import jp from 'jsonpath';
import type { EntityDetail, EntityDetailSchema } from '@web-config-app/core';
import {
  computeSchema,
  findImageAssetIdSchemaNodes,
} from '@web-config-app/schema-utils';

// Schema keywords to filter out
const SCHEMA_KEYWORDS = ['properties', 'oneOf', 'anyOf', 'allOf'];

/**
 * Converts a JSON Schema property path to JSONPath expression
 * @param schemaPath - Schema path (e.g. "properties.images.items.properties.url")
 * @returns JSONPath expression (e.g. "$.images[*].url")
 */
export const convertSchemaPathToJsonPath = (schemaPath: string) => {
  const pathParts = schemaPath.split('.');

  // Filter out schema keywords and rejoin
  const dataPath = pathParts
    .filter((part) => !SCHEMA_KEYWORDS.includes(part))
    .join('.');

  // Replace array notation and add root symbol
  const jsonPath = `$.${dataPath}`
    .replaceAll('.items.', '[*].')
    // Handle case where items is last segment
    .replaceAll('.items', '[*]');

  return jsonPath;
};

/**
 * Extracts asset reference IDs from an entity based on its schema.
 * This function traverses through an entity's schema to find image asset references
 * and retrieves their corresponding IDs from the entity data.
 *
 * @param schema - The schema definition describing the structure of the entity
 * @param entityData - The partial entity data containing the actual values
 * @returns An array of string IDs representing the asset references found in the entity
 *
 * @example
 * ```typescript
 * const schema = {
 *   properties: {
 *     image: { type: 'imageAsset' }
 *   }
 * };
 * const entityData = {
 *   image: 'asset-123'
 * };
 * const assetIds = getEntityAssetReferenceIds(schema, entityData);
 * // Returns: ['asset-123']
 * ```
 */

export const getEntityAssetReferenceIds = (
  schema: EntityDetailSchema,
  entityData: Partial<EntityDetail> | undefined,
) => {
  /**
   * Returns a list of data paths that contain an image asset entity id
   */
  const foundAssetDataPaths = new Set<string>();
  computeSchema(schema, undefined, [findImageAssetIdSchemaNodes], {
    onSchemaPropertyMatch: (assetSchemaPath) => {
      const jsonPath = convertSchemaPathToJsonPath(assetSchemaPath);
      foundAssetDataPaths.add(jsonPath);
    },
  });

  /**
   * Map the dataPaths to the value of the data at that position
   */
  return Array.from(foundAssetDataPaths.values()).reduce(
    (assetReferenceIds: string[], dataPath) => {
      // jp.query always returns an array, even for a single object property
      const dataAtPath = entityData && jp.query(entityData, dataPath);

      if (Array.isArray(dataAtPath)) {
        return [
          ...assetReferenceIds,
          // Filter out `undefined` and empty strings
          ...dataAtPath.filter(
            (value) => typeof value === 'string' && value.length > 0,
          ),
        ];
      }

      return assetReferenceIds;
    },
    [],
  );
};
