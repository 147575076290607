import * as React from 'react';
import {
  Button,
  GDSStyleObject,
  GDSIconProps,
} from '@leagueplatform/genesis-core';

type IconButtonProps = {
  onClick?: () => void;
  icon: GDSIconProps['icon'];
  label: string;
  size?: 'small' | 'medium';
  css?: GDSStyleObject;
  disabled?: boolean;
};

export const IconButton = React.forwardRef(
  (
    { onClick, icon, label, size = 'medium', css, disabled }: IconButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <Button
      ref={ref}
      hideLabel
      icon={`${icon}`}
      priority="secondary"
      quiet
      size={size}
      onClick={onClick}
      disabled={disabled}
      css={css}
    >
      {label}
    </Button>
  ),
);
