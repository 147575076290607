import * as React from 'react';
import { EntityDetail } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { EntityName } from '@web-config-app/core-react-ui';
import type {
  ControlProps,
  EntityReferenceControlSchema,
} from '../../../types/controls';
import { useAnnotatedSchemaProps } from '../../../hooks/use-annotated-schema-props/use-annotated-schema-props';
import { useEntityReferenceSchema } from '../../../hooks/use-entity-reference-schema/use-entity-reference-schema';
import { useEntityFormHandleChange } from '../../../hooks/use-entity-form-handle-change/use-entity-form-handle-change';
import { useReferencedEntityData } from '../../../hooks/use-referenced-entity-data/use-referenced-entity-data';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';
import { useAddEntityById } from './use-add-entity-by-id.hook';
import { EntityReferenceControlPresenter } from './entity-reference-control-presenter.component';
import { getComposedSchemaPropertyPath } from '../../../utils/get-composed-schema-property-path/get-composed-schema-property-path';

const getIndexFromPath = (path: string) => {
  const possibleArrayIndexPath = path.split('.').pop();
  const possibleArrayIndex = parseInt(possibleArrayIndexPath ?? '', 10);
  return Number.isFinite(possibleArrayIndex) && possibleArrayIndex >= 0
    ? possibleArrayIndex
    : undefined;
};

const getInstanceIdFromData = ({
  attributeData,
  relationshipData,
  index,
}: {
  attributeData?: any;
  relationshipData?: any;
  index?: number;
}): string | undefined => {
  if (attributeData) {
    return attributeData;
  }

  if (Array.isArray(relationshipData) && Number.isFinite(index)) {
    return relationshipData[Number(index)].id;
  }

  return relationshipData?.id;
};

/**
 * Renders for schemas that have a defined `x-entity-reference.relationship` value. This control can operate on
 * entity references that are pure attributes (ex: a string ID), relationships, or both.
 */

export const EntityReferenceControl: React.FC<ControlProps> = ({
  schema,
  handleChange,
  path,
  data,
}) => {
  const { formatMessage } = useIntl();
  const { formPath } = useEntityDetailsProps();
  const { label, hint, errors, inputStatus, banner } = useAnnotatedSchemaProps(
    schema,
    null,
    getComposedSchemaPropertyPath(formPath, path),
  );

  /**
   * Some entity references exist as a relationship, while others are actually part of the
   * schema (ex: a property called `activityId` that is a string and stores the entity reference
   * as an attribute.
   *
   * (some references use both relationships AND an attribute, FUN!)
   *
   * Anyway, this abstracts out the attribute one into a function we can pass into
   * `useEntityReference` so that on any change we can handle both the attribute and the
   * relationship simultaneously
   */

  const handleEntityFormChange = useEntityFormHandleChange({
    schema,
    handleChange,
    path,
  });

  /**
   * Pass the EntityForm change handler into `useEntityReferenceSchema` so it can create
   * `handleRelationshipChange` which will handle all the various scenarios we could encounter:
   *
   * 1. the reference is an id attribute
   * 2. the reference is a relationship
   * 3. the reference is both an id attribute AND a relationship
   * 4. the reference is an id attribute in an array of attributes
   * 5. the reference is an id attribute of an object array
   * 6. the reference is an array of relationships
   */

  const {
    entity,
    handleRelationshipChange,
    relationshipDataSchemaType,
    value: relationshipData,
  } = useEntityReferenceSchema({
    schema: schema as EntityReferenceControlSchema,
    handleEntityFormChange,
  });

  const { currentAuthoringEnvironment } = useEntityDetailsProps();

  /**
   * Grab props to be pass to the AddItemByIdModal component that allows a user to enter an entity ID
   */
  const {
    idValue,
    setIdValue,
    findEntityById,
    isLoading: isLoadingByIdSubmission,
    isError: isLoadingByIdError,
  } = useAddEntityById(entity, {
    enabled: false,
    retry: 1,
  });

  const maybeArrayItemIndex =
    relationshipDataSchemaType === 'array' ? getIndexFromPath(path) : undefined;
  const instanceId = getInstanceIdFromData({
    attributeData: data,
    relationshipData,
    index: maybeArrayItemIndex,
  });

  /**
   * `useReferencedEntityData` allows us to retrieve the name and status of the entity to display
   * in the control.
   */

  const {
    referencedEntity: entityReferenceData,
    isLoading,
    isError,
  } = useReferencedEntityData({
    entity,
    instanceId,
    environment: currentAuthoringEnvironment,
  });

  /**
   * Entity references require special handling for the `relationships`, which can either be a single
   * entity or an array of entities. This will only be useful in the latter case (array), so we need
   * the array index to pass to our relationships handlers, which we can somewhat optimistically
   * retrieve from `path` which, in the case of a control being inside an array, should contain
   * the array index item as the last part of the path (ex: 'components.0')
   */

  const [isAddingById, setIsAddingById] = React.useState<boolean>(false);
  const [isAddingFromList, setIsAddingFromList] =
    React.useState<boolean>(false);

  const openChooseFromList = () => {
    setIsAddingFromList(true);
  };
  const closeChooseFromList = () => {
    setIsAddingFromList(false);
  };
  const openAddById = () => {
    setIsAddingById(true);
  };
  const closeAddById = () => {
    setIsAddingById(false);
  };

  const modalHeadingLabel = formatMessage(
    { id: 'CHOOSE_ENTITY' },
    {
      entityName: entity.nameTranslationKey ? (
        <EntityName id={entity.nameTranslationKey} singular withArticle />
      ) : (
        entity.name
      ),
    },
  );

  const addItemTriggerList = [
    {
      buttonLabel: formatMessage({
        id: 'ADD_BY_ID',
      }),
      openFn: openAddById,
    },
    {
      buttonLabel: formatMessage({
        id: 'CHOOSE_FROM_LIST',
      }),
      openFn: openChooseFromList,
    },
  ];

  const onSubmitAddById = () => {
    findEntityById({
      onSuccess: ({ data: response }) => {
        const entityDetail = response.data;
        handleRelationshipChange?.(
          entityDetail,
          entityReferenceData,
          maybeArrayItemIndex,
        );
        closeAddById();
      },
    });
  };

  const onSubmitChooseFromList = (args: EntityDetail) => {
    handleRelationshipChange?.(args, entityReferenceData, maybeArrayItemIndex);
    closeChooseFromList();
  };

  return (
    <EntityReferenceControlPresenter
      hint={hint}
      label={label!}
      errors={errors!}
      inputStatus={inputStatus}
      banner={banner}
      entityReferenceData={entityReferenceData}
      isLoading={isLoading}
      isError={isError}
      isLoadingByIdSubmission={isLoadingByIdSubmission}
      isLoadingByIdError={isLoadingByIdError}
      onSubmitAddById={onSubmitAddById}
      onSubmitChooseFromList={onSubmitChooseFromList}
      addItemTriggerList={addItemTriggerList}
      isAddingById={isAddingById}
      isAddingFromList={isAddingFromList}
      closeAddById={closeAddById}
      closeChooseFromList={closeChooseFromList}
      modalHeadingLabel={modalHeadingLabel}
      entity={entity}
      openChooseFromList={openChooseFromList}
      idValue={idValue}
      setIdValue={setIdValue}
    />
  );
};
