import type {
  EndpointPathParameter,
  ComputedEndpointParam,
} from '@web-config-app/core';

/**
 * the path parameters name value is used in the params prop of endpointFetch and is
 * used to replace the id placeholder in the path with the value of instanceId
 */
export const getIdParam = (
  pathParameters: EndpointPathParameter[],
  path: string,
  instanceId: string | undefined,
): ComputedEndpointParam => {
  /**
   * Fine the param whose name matches the placeholder pattern in the endpoint path,
   * @example
   * path = `v1/config-cactuses/{cactusId}
   * param = { name: `cactusId`, ... }
   */
  const entityInstanceIdParam = pathParameters?.find(
    (param) => path.indexOf(`${param.name}`) > -1,
  );

  const entityInstanceIdParamName = entityInstanceIdParam?.name;

  return instanceId && entityInstanceIdParamName
    ? {
        name: entityInstanceIdParamName,
        value: instanceId,
      }
    : null;
};
