/**
 * Util function to construct a new file object from existing config asset image data
 */

export interface ConfigAssetData {
  attributes: { url?: string; contentType?: string; fileName?: string };
}

export const getCreateConfigAssetPayload = async (data: ConfigAssetData) => {
  const { url, fileName, contentType } = data.attributes;
  if (!url || !fileName || !contentType) return undefined;

  /**
   * Download the image data from the provided URL and construct a new File object
   */
  const imageData = await fetch(url).then((response) => response.blob());
  const file =
    imageData && new File([imageData], fileName, { type: contentType });

  /**
   * The `v1/config-assets` endpoint expects the body to be sent as a FormData object
   */
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  formData.append('file', file);

  return formData;
};
