import React from 'react';
import { isEmpty } from 'lodash-es';
import {
  findUISchema,
  Generate,
  JsonFormsUISchemaRegistryEntry,
  Actions,
} from '@jsonforms/core';
import { unsetObjectProperty } from '@web-config-app/core';
import { JsonFormsDispatch, useJsonForms } from '@jsonforms/react';
import {
  computeSchema,
  applyConditionalLogic,
} from '@web-config-app/schema-utils';
import { EntityFormStatePropsOfControlWithDetail } from '../../../types/controls';

/**    
 * 
This borrows from the MaterialObjectRenderer here: https://github.com/eclipsesource/jsonforms/blob/ba6bdc5a93044a872285cd71ed40d9a54f437561/packages/material/src/complex/MaterialObjectRenderer.tsx

It calls JsonFormsDispatch which parses the UI schema dispatches the rendering process to appropriate nested components and custom renderers. JsonFormsDispatch also works to resolve schemas using $refs.

Object control renders if something is of Control and type object, it delegates the rendering of the objects individual fields.

If an object that passes through the ObjectControl is not a PrimitiveObjectType, then before the JsonFormDispatch the "Object Link" is rendered

Note: ObjectControl calls useAnnotatedSchemaProps directly and is props wrapper-less as it is a one-off control component 
 * 
*/

export const ObjectControl = ({
  renderers,
  cells,
  // setting as empty array to safeguard for findUISchema
  uischemas = [],
  schema,
  path,
  visible,
  enabled,
  uischema,
  rootSchema,
  data,
}: EntityFormStatePropsOfControlWithDetail) => {
  const isRoot = isEmpty(path);
  const ctx = useJsonForms();
  const { dispatch } = ctx;
  /**
   * When the data property at an array path is missing from the backend GET request of an entity, the JsonForms library (or some other factor) is incorrectly setting it as an empty object {}, instead of the expected empty array [].
   *
   * This useEffect hook ensures that the property at this path is always an array.
   *  If it's missing or not an array, it's forcefully updated to an empty array [].
   *
   * this prevents the error of JSON Forms trying to push an item to an object
   */

  const computed = React.useMemo(() => {
    const computedSchema = computeSchema(
      schema,
      data,
      [applyConditionalLogic],
      {
        recursive: false,
        path,
        onSchemaPropertyRemoved: (dataPath: string, propertyName: string) => {
          const { data: newData, updated } = unsetObjectProperty({
            data,
            dataPath,
            propertyName,
          });
          if (updated) {
            dispatch?.(Actions.update(path, () => newData));
          }
        },
      },
    );

    const detailUiSchema = findUISchema(
      uischemas as JsonFormsUISchemaRegistryEntry[],
      computedSchema,
      uischema.scope,
      path,
      // this is the fallback function to use if there is no uiSchema found
      () =>
        // if at the schema root, this returns "VerticalLayout" type for uischema. This matches default json forms uischema behavior and is useful because if something is at the root, we always want to render it the same way (even if technically it is not a complex object schema)
        Generate.uiSchema(
          computedSchema,
          isRoot ? 'VerticalLayout' : 'Group',
          undefined,
          rootSchema,
        ),
      uischema,
      rootSchema,
    );

    return {
      schema: computedSchema,
      uiSchema: detailUiSchema,
    };
  }, [path, uischema, uischemas, schema, rootSchema, data, isRoot, dispatch]);
  return (
    <JsonFormsDispatch
      visible={visible}
      enabled={enabled}
      schema={computed.schema}
      uischema={computed.uiSchema}
      path={path}
      renderers={renderers}
      cells={cells}
    />
  );
};
