export const EXAMPLE_ENTITY_STRINGS = {
  EXAMPLE_ENTITY: 'Example Entity',
  EXAMPLE_PRIMITIVE_ATTRIBUTES: 'Primitive types',
  EXAMPLE_PRIMITIVE_ATTRIBUTES_STRING: 'String type',
  EXAMPLE_PRIMITIVE_ATTRIBUTES_ENUM: 'Enum type',
  EXAMPLE_PRIMITIVE_ATTRIBUTES_NUMBER: 'Number type',
  EXAMPLE_OBJECT_ARRAY_ATTRIBUTES: 'Array of objects types',
  EXAMPLE_PRIMITIVE_ARRAY: 'Primitive array type',
  EXAMPLE_PRIMITIVE_ARRAY_ITEM: 'Example Tag',
  EXAMPLE_PRIMITIVE_ARRAY_HINT:
    'Primitive arrays allow you manage collections of primitive types (string/number/date etc.)',

  EXAMPLE_PRIMITIVE_OBJECT_ARRAY: 'Primitive object array type',
  EXAMPLE_PRIMITIVE_ARRAY_BANNER_TITLE: 'Primitive object arrays',
  EXAMPLE_PRIMITIVE_ARRAY_BANNER_DESCRIPTION:
    'Arrays of objects with properties that are only primitive types render the entire control on one screen',
  PRIMITIVE_OBJECT: 'Primitive Object',
  EXAMPLE_COMPLEX_OBJECT_ARRAY: 'Complex object arrays',
  EXAMPLE_COMPLEX_ARRAY_BANNER_DESCRIPTION:
    'Arrays of objects that have any object or array properties render as cards and are added to the tree navigation',
  EXAMPLE_COMPLEX_OBJECT: 'Complex Object',
  /**
   * Add key/string pairs here that you've added to the example entity schema
   */
};
