/* istanbul ignore file */
import type { AuthoringEnvironmentNameKeyMap } from './types/authoring';

export const environmentKeys: AuthoringEnvironmentNameKeyMap = {
  production: 'PRODUCTION',
  uat: 'UAT',
  staging: 'STAGING',
  development: 'DEVELOPMENT',
  test: 'TEST',
};

export const enum EntityStatusValue {
  Published = 'published',
  Archived = 'archived',
  Draft = 'draft',
  Default = 'default',
  // Used to keep track of new entities without a BE state
  New = 'new',
}

export enum EntityOperationStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export const enum EntityDetailAction {
  Create = 'create',
  Update = 'update',
  Publish = 'publish',
  Republish = 'republish',
  Unpublish = 'unpublish',
  Archive = 'archive',
  Unarchive = 'unarchive',
  DuplicateAsDraft = 'duplicateAsDraft',
  ExitAndDiscardChanges = 'exitAndDiscardChanges',
}

// There's some places in code that could be updated to use OperationMethod instead
// of using hard-coded strings
// https://everlong.atlassian.net/browse/CACT-1159

export enum OperationMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
/**
 * Category types for Domains and Entities - 'hidden' will hide the domain and entity from the navigation menu and entities with 'hidden' will not have a list page
 */
export enum Category {
  Default = 'default',
  Hidden = 'hidden',
}

export enum Operation {
  List = 'list',
  Get = 'get',
  Create = 'create',
  Update = 'update',
  Archive = 'archive',
  Publish = 'publish',
  Republish = 'republish',
  Search = 'search',
  Unpublish = 'unpublish',
  Unarchive = 'unarchive',
  Delete = 'delete',
  DuplicateAsDraft = 'duplicateAsDraft',
  CopyToEnvironment = 'copyToEnvironment',
}

export enum RulesTemplateType {
  completionCriteria = 'completionCriteria',
  rewardCriteria = 'rewardCriteria',
  eligibilityCriteria = 'eligibilityCriteria',
  filterRule = 'filterRule',
  branchingRule = 'branchingRule',
  challengeRule = 'challengeRule',
}

export enum WorkflowActionModal {
  confirmation = 'confirmation',
}

export const entityDetailActionPendingKey: Partial<Record<Operation, string>> =
  {
    create: 'SAVING_ACTION',
    update: 'SAVING_ACTION',
    publish: 'PUBLISHING_ACTION',
    republish: 'REPUBLISHING_ACTION',
    unpublish: 'UNPUBLISHING_ACTION',
    archive: 'ARCHIVING_ACTION',
    unarchive: 'UNARCHIVING_ACTION',
    duplicateAsDraft: 'DUPLICATING_AS_DRAFT_ACTION',
    copyToEnvironment: 'COPYING_TO_ENVIRONMENT_ACTION',
  };
