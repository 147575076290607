import React from 'react';
import {
  VisuallyHidden,
  Spinner,
  StatusBanner,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { UseBaseQueryResult } from '@tanstack/react-query';
import { useIntl } from '@leagueplatform/locales';
import {
  AnnotatedJsonSchema,
  AuthoringEnvironmentId,
  Entity,
  EntityStatus,
  EntityStatusValue,
} from '@web-config-app/core';
import {
  bannerAnimation,
  EntityDetailsPageLayout,
  ErrorBanner,
  ErrorBoundaryWrapper,
  ErrorPage,
  LoadingOverlayModal,
} from '@web-config-app/core-react-ui';
import {
  EntityForm,
  EntityTreeView,
  useEntityFormState,
  createErrorFilteringMiddleware,
} from '@web-config-app/entity-form';
import {
  useConfigErrorBanner,
  useConfigUserFeedback,
} from '@web-config-app/core-react';
import type {
  GetEntityDetailsPathForEnvAndId,
  GetEntityCreatePathForEnv,
} from '@web-config-app/core-react';
import { EntityDetailsHeaderContainer } from '../../containers/entity-details-header-container/entity-details-header.container';
import {
  EntityFormSkeleton,
  SidePanelSkeleton,
} from './entity-details-skeleton.component';

export type EntityDetailsPageProps = Pick<
  UseBaseQueryResult,
  'isError' | 'isLoading'
> & {
  formData: any;
  formSchema?: AnnotatedJsonSchema;
  formPath?: string;
  entity: Entity;
  entityListPath: string;
  entityTree: any;
  onFormDataChange: (updatedData: any) => void;
  formDataChangeDebounceLength?: number;
  handleNodeToggle?: (nodeId: string) => void;
  expandedNodes?: string[];
  publishDangerously: boolean;
  environmentName: string;
  entityStatus?: EntityStatus;
  environmentId: AuthoringEnvironmentId;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  getEntityCreatePathForEnv: GetEntityCreatePathForEnv;
  setEntityName: (name: string) => void;
};

export const EntityDetailsPage: React.FC<EntityDetailsPageProps> = ({
  isLoading,
  isError: isFullPageError,
  formData,
  formSchema,
  formPath,
  entity,
  entityListPath,
  entityTree,
  handleNodeToggle,
  onFormDataChange,
  expandedNodes,
  publishDangerously,
  entityStatus,
  environmentName,
  formDataChangeDebounceLength = 400,
  environmentId,
  getEntityDetailsPathForEnvAndId,
  getEntityCreatePathForEnv,
  setEntityName,
}) => {
  const { formatMessage } = useIntl();
  const { formDataChangeHandler, localData, localSchema } = useEntityFormState({
    onFormDataChange,
    formDataChangeDebounceLength,
    formPath,
    formData,
    formSchema,
  });

  const { entityOperationLoadingMsg } = useConfigUserFeedback((state) => state);

  const {
    showErrorBanner,
    hideErrorBanner,
    errorBannerTitle,
    errorBannerContents,
    showFormValidation,
    filteredErrorKeywords,
  } = useConfigErrorBanner();

  if (isFullPageError) {
    return <ErrorPage />;
  }
  const entityFormMiddleware = createErrorFilteringMiddleware(
    filteredErrorKeywords,
  );

  const showPublishDangerouslyBanner =
    publishDangerously && entityStatus === EntityStatusValue.Published;

  return (
    <EntityDetailsPageLayout
      topBar={
        <EntityDetailsHeaderContainer
          setEntityName={setEntityName}
          isLoading={isLoading}
          entity={entity}
          entityListPath={entityListPath}
          environmentId={environmentId}
          environmentName={environmentName}
          publishDangerously={publishDangerously}
          getEntityDetailsPathForEnvAndId={getEntityDetailsPathForEnvAndId}
          getEntityCreatePathForEnv={getEntityCreatePathForEnv}
        />
      }
      statusBanners={
        <>
          {showPublishDangerouslyBanner && (
            <StatusBanner
              data-testid="publish-dangerously-banner"
              showIcon={false}
              status="warning"
              css={{ animation: bannerAnimation }}
            >
              <ParagraphText>
                {formatMessage(
                  { id: 'PUBLISHING_DANGEROUSLY_STATUS_BANNER' },
                  { environmentName },
                )}
              </ParagraphText>
            </StatusBanner>
          )}
          {showErrorBanner && (
            <ErrorBanner
              title={errorBannerTitle}
              onDismissClick={hideErrorBanner}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...errorBannerContents}
            />
          )}
        </>
      }
      sidePanel={
        isLoading ? (
          <SidePanelSkeleton />
        ) : (
          <EntityTreeView
            entityTree={entityTree}
            onSelect={handleNodeToggle}
            activeTreeNodeId={formPath}
            expandedItems={expandedNodes}
          />
        )
      }
    >
      <>
        {Boolean(entityOperationLoadingMsg) && (
          <LoadingOverlayModal
            isOpen={Boolean(entityOperationLoadingMsg)}
            loadingText={`${entityOperationLoadingMsg}`}
          >
            <ParagraphText aria-hidden>
              {entityOperationLoadingMsg}
            </ParagraphText>
          </LoadingOverlayModal>
        )}
        <VisuallyHidden>
          <Spinner
            loading={isLoading}
            loadingText={formatMessage({ id: 'LOADING' })}
          />
        </VisuallyHidden>
        {isLoading ? (
          <EntityFormSkeleton />
        ) : (
          <ErrorBoundaryWrapper>
            <EntityForm
              schema={localSchema}
              data={localData}
              onDataChange={formDataChangeHandler}
              showValidation={showFormValidation}
              middleware={entityFormMiddleware}
            />
          </ErrorBoundaryWrapper>
        )}
      </>
    </EntityDetailsPageLayout>
  );
};
