import { useMemo } from 'react';
import {
  useEntity,
  useEntityList,
  useConfigApp,
  useConfigAppPath,
  useConfigAppParams,
  useAuthoringEnvironments,
} from '@web-config-app/core-react';
import { useHistory } from '@leagueplatform/routing';
import { Category } from '@web-config-app/core';
import { EntityListPageViewProps } from '@web-config-app/core-react-ui';
import { useIntl } from '@leagueplatform/locales';

interface EntityListPageContainerProps {
  entityListPageContentsRenderer: (
    props: EntityListPageViewProps,
  ) => JSX.Element;
}

export function EntityListPageContainer({
  entityListPageContentsRenderer,
}: EntityListPageContainerProps) {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { domainId, entityId, environmentId } = useConfigAppParams();
  const entity = useEntity({ domainId, entityId });
  const generateConfigPaths = useConfigAppPath();

  const { getEntityDetailsPathForId, entityCreatePath } = generateConfigPaths({
    domainId,
    entityId,
    environmentId,
  });

  const { tenantName } = useConfigApp();
  const {
    currentAuthoringEnvironment,
    environmentMap,
    multiEnvironmentAuthoringEnabled,
  } = useAuthoringEnvironments({
    environmentId,
  });
  const { pagination, data, isError, isLoading, loadPage } = useEntityList({
    entity,
    environment: currentAuthoringEnvironment,
  });
  const displayEmptyCard = isError || (!isLoading && !data?.length);
  const emptyCardHeading = formatMessage(
    { id: isError ? 'ERROR_ENTITY_TITLE' : 'EMPTY_ENTITY_TITLE' },
    { entity: entity.name },
  );

  const emptyCardDescription = formatMessage(
    {
      id: isError ? 'ERROR_ENTITY_DESCRIPTION' : 'EMPTY_ENTITY_DESCRIPTION',
    },
    { entity: entity.name, tenant: tenantName },
  );

  const availableEnvironments = useMemo(
    () =>
      multiEnvironmentAuthoringEnabled && environmentMap instanceof Map
        ? Array.from(environmentMap.values()).map?.((clientInfo) => ({
            id: clientInfo.id,
            label: formatMessage({ id: clientInfo.nameKey }),
            action: () => {
              const { entityListPath } = generateConfigPaths({
                domainId,
                entityId,
                environmentId: clientInfo.id,
              });
              history.push(entityListPath);
            },
          }))
        : null,
    [
      multiEnvironmentAuthoringEnabled,
      environmentMap,
      domainId,
      entityId,
      generateConfigPaths,
      history,
      formatMessage,
    ],
  );

  if (entity.category === Category.Hidden) {
    throw new Error(`Entity ${entity.id} list page does not exist`);
  }

  return entityListPageContentsRenderer({
    displayEmptyCard,
    emptyCardHeading,
    emptyCardDescription,
    availableEnvironments,
    currentAuthoringEnvironment,
    multiEnvironmentAuthoringEnabled,
    getEntityDetailsPathForId,
    entityCreatePath,
    pagination,
    loadPage,
    isLoading,
    data,
    entity,
  });
}
