import type { LeagueConfig } from '@leagueplatform/core';
import type { AuthoringEnvironmentId } from '@web-config-app/core';

const AUTH0_DOMAIN_STAGING = 'config.test.league.dev';
const AUTH0_DOMAIN_PRODUCTION = 'config.league.com';

const AUTH0_CLIENT_ID_STAGING = 'V4bK6miilKNLl5w7b4Y6rHZnfEYhPNP2';
const AUTH0_CLIENT_ID_PRODUCTION = '2zjYYjkN3gPrHThT4C1q9YgQ2ZN0fnVX';

export interface EnvironmentDetails {
  appEnvironment: LeagueConfig['core']['appEnvironment'];
  environmentId: AuthoringEnvironmentId;
  environmentLabel: string;
  auth0Domain: string;
  auth0ClientId: string;
  urls: EnvironmentUrls;
}

interface EnvironmentUrls {
  auth0PrimaryAudience: string;
  wsApiUrl: string;
  apiUrl: string;
  legacyRestApi: string;
  contentUrl: string;
}

export enum EnvironmentLabel {
  CA_TEST = 'ca:test',
  CA_STAGING = 'ca:staging',
  CA_PRODUCTION = 'ca:production',
  US_STAGING = 'us:staging',
  US_UAT = 'us:uat',
  US_PRODUCTION = 'us:production',
  AU_STAGING = 'au:staging',
  AU_UAT = 'au:uat',
  AU_PRODUCTION = 'au:production',
  HM_STAGING = 'hm:staging',
  HM_UAT = 'hm:uat',
  HM_PRODUCTION = 'hm:production',
  CIGNA_UAT = 'cigna:uat',
  OPTUM_UAT = 'optum:uat',
}

type EnvironmentList = Record<`${EnvironmentLabel}`, EnvironmentDetails>;

export const environmentList: EnvironmentList = {
  'ca:test': {
    appEnvironment: 'development',
    environmentId: 'test',
    environmentLabel: 'Test',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.staging.league.dev',
      wsApiUrl: 'wss://api.test4.league.dev',
      apiUrl: 'https://api.test4.league.dev',
      legacyRestApi: 'https://rest.test4.league.dev',
      contentUrl: 'https://content.test4.league.dev',
    },
  },
  'ca:staging': {
    appEnvironment: 'staging',
    environmentId: 'staging',
    environmentLabel: 'Staging',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.staging.league.dev',
      wsApiUrl: 'wss://api.staging.league.dev/ws',
      apiUrl: 'https://api.staging.league.dev',
      legacyRestApi: 'https://rest.staging.league.dev',
      contentUrl: 'https://content.staging.league.dev',
    },
  },
  'ca:production': {
    appEnvironment: 'production',
    environmentId: 'production',
    environmentLabel: 'Production',
    auth0ClientId: AUTH0_CLIENT_ID_PRODUCTION,
    auth0Domain: AUTH0_DOMAIN_PRODUCTION,
    urls: {
      auth0PrimaryAudience: 'https://core-api.league.com',
      wsApiUrl: 'wss://api.league.com/ws',
      apiUrl: 'https://api.league.com',
      legacyRestApi: 'https://rest.league.com',
      contentUrl: 'https://content.league.com',
    },
  },
  'us:staging': {
    appEnvironment: 'staging',
    environmentId: 'staging',
    environmentLabel: 'Staging',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.test5-us.league.dev',
      wsApiUrl: 'wss://api.test5-us.league.dev/ws',
      apiUrl: 'https://api.test5-us.league.dev',
      legacyRestApi: 'https://rest.test5-us.league.dev',
      contentUrl: 'https://content.test5-us.league.dev',
    },
  },
  'us:uat': {
    appEnvironment: 'staging',
    environmentId: 'uat',
    environmentLabel: 'UAT',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.uat.us.league.dev',
      wsApiUrl: 'wss://api.uat.us.league.dev/ws',
      apiUrl: 'https://api.uat.us.league.dev',
      legacyRestApi: 'https://rest.uat.us.league.dev',
      contentUrl: 'https://content.uat.us.league.dev',
    },
  },
  'us:production': {
    appEnvironment: 'production',
    environmentId: 'production',
    environmentLabel: 'Production',
    auth0ClientId: AUTH0_CLIENT_ID_PRODUCTION,
    auth0Domain: AUTH0_DOMAIN_PRODUCTION,
    urls: {
      auth0PrimaryAudience: 'https://core-api.us.league.com',
      wsApiUrl: 'wss://api.us.league.com/ws',
      apiUrl: 'https://api.us.league.com',
      legacyRestApi: 'https://rest.us.league.com',
      contentUrl: 'https://content.us.league.com',
    },
  },
  'au:staging': {
    appEnvironment: 'staging',
    environmentId: 'staging',
    environmentLabel: 'Staging',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.test1.au.league.dev',
      wsApiUrl: 'wss://api.test1.au.league.dev/ws',
      apiUrl: 'https://api.test1.au.league.dev',
      legacyRestApi: 'https://rest.test1.au.league.dev',
      contentUrl: 'https://content.test1.au.league.dev',
    },
  },
  'au:uat': {
    appEnvironment: 'staging',
    environmentId: 'uat',
    environmentLabel: 'UAT',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.uat.au.league.dev',
      wsApiUrl: 'wss://api.uat.au.league.dev/ws',
      apiUrl: 'https://api.uat.au.league.dev',
      legacyRestApi: 'https://rest.uat.au.league.dev',
      contentUrl: 'https://content.uat.au.league.dev',
    },
  },
  'au:production': {
    appEnvironment: 'production',
    environmentId: 'production',
    environmentLabel: 'Production',
    auth0ClientId: AUTH0_CLIENT_ID_PRODUCTION,
    auth0Domain: AUTH0_DOMAIN_PRODUCTION,
    urls: {
      auth0PrimaryAudience: 'https://core-api.prod.au.league.dev',
      wsApiUrl: 'wss://api.prod.au.league.dev/ws',
      apiUrl: 'https://api.prod.au.league.dev',
      legacyRestApi: 'https://rest.prod.au.league.dev',
      contentUrl: 'https://content.prod.au.league.dev',
    },
  },
  'hm:staging': {
    appEnvironment: 'staging',
    environmentId: 'staging',
    environmentLabel: 'Staging',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.hm-test.league.dev',
      wsApiUrl: 'wss://api.hm-test.league.dev/ws',
      apiUrl: 'https://api.hm-test.league.dev',
      legacyRestApi: 'https://rest.hm-test.league.dev',
      contentUrl: 'https://content.hm-test.league.dev',
    },
  },
  'hm:uat': {
    appEnvironment: 'staging',
    environmentId: 'uat',
    environmentLabel: 'UAT',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.hm-uat.league.dev',
      wsApiUrl: 'wss://api.hm-uat.league.dev/ws',
      apiUrl: 'https://api.hm-uat.league.dev',
      legacyRestApi: 'https://rest.hm-uat.league.dev',
      contentUrl: 'https://content.hm-uat.league.dev',
    },
  },
  'hm:production': {
    appEnvironment: 'production',
    environmentId: 'production',
    environmentLabel: 'Production',
    auth0ClientId: AUTH0_CLIENT_ID_PRODUCTION,
    auth0Domain: AUTH0_DOMAIN_PRODUCTION,
    urls: {
      auth0PrimaryAudience: 'https://core-api.hm.league.com',
      wsApiUrl: 'wss://api.hm.league.com/ws',
      apiUrl: 'https://api.hm.league.com',
      legacyRestApi: 'https://rest.hm.league.com',
      contentUrl: 'https://content.hm.league.com',
    },
  },
  'cigna:uat': {
    appEnvironment: 'staging',
    environmentId: 'uat',
    environmentLabel: 'UAT',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.uat.cigna.league.dev',
      wsApiUrl: 'wss://api.uat.cigna.league.dev/ws',
      apiUrl: 'https://api.uat.cigna.league.dev',
      legacyRestApi: 'https://rest.uat.cigna.league.dev',
      contentUrl: 'https://content.uat.cigna.league.dev',
    },
  },
  'optum:uat': {
    appEnvironment: 'staging',
    environmentId: 'uat',
    environmentLabel: 'UAT',
    auth0ClientId: AUTH0_CLIENT_ID_STAGING,
    auth0Domain: AUTH0_DOMAIN_STAGING,
    urls: {
      auth0PrimaryAudience: 'https://core-api.uat.optum.league.dev',
      wsApiUrl: 'wss://api.uat.optum.league.dev/ws',
      apiUrl: 'https://api.uat.optum.league.dev',
      legacyRestApi: 'https://rest.uat.optum.league.dev',
      contentUrl: 'https://content.uat.optum.league.dev',
    },
  },
};
