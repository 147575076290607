import { useIntl } from '@leagueplatform/locales';

type FormatMessage = ReturnType<typeof useIntl>['formatMessage'];

interface GetEnvironmentSelectModalProps {
  entityName: string;
  showModal?: boolean;
  formatMessage: FormatMessage;
}

/**
 * getEnvironmentSelectModalProps function generates the properties for a confirmation modal in the `EnvironmentSelect` component
 *
 * @param {string} params.entityName - The name of the entity - shows in modal title.
 * @param {boolean} [params.showModal=false] - Flag to indicate whether to show the modal.
 * @param {FormatMessage} params.formatMessage - Function to format messages.
 * @returns {object | undefined} - An object containing modal properties if showModal is true, otherwise undefined.
 */
export const getEnvironmentSelectModalProps = ({
  entityName,
  showModal,
  formatMessage,
}: GetEnvironmentSelectModalProps) => {
  if (showModal) {
    return {
      title: formatMessage(
        { id: 'EXIT_ENTITY_DETAIL_TITLE' },
        { entity: entityName },
      ),
      description: formatMessage({ id: 'EXIT_ENVIRONMENT_MODAL_DESCRIPTION' }),
      confirmCta: formatMessage({
        id: 'EXIT_ENVIRONMENT_MODAL_CONFIRM_CTA',
      }),
    };
  }
  return undefined;
};
