import jp from 'jsonpath';
import { EntityRootData, Entity } from '../types/entities';

export const getEntityInstanceName = (
  data: EntityRootData | undefined,
  entity: Entity,
) => {
  if (typeof data === 'undefined') {
    return null;
  }

  const customEntityNamePath =
    entity.schema['x-entity-metadata']?.entityNamePath;

  if (typeof customEntityNamePath === 'undefined') {
    return data?.attributes?.entityMetadata?.name;
  }

  const instanceName = jp.value(data, customEntityNamePath);

  return instanceName;
};
