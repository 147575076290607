import type {
  GetEndpointResponse,
  AuthoringEnvironmentId,
} from '@web-config-app/core';
import { useQueryClient } from '@tanstack/react-query';
import type { CopyAssetToEnvironmentResult } from '../../types/operation.types';
import { getEndpointParamsWithValues } from '../../utilities/get-endpoint-params-with-values/get-endpoint-params-with-values';
import { getIdParam } from '../../utilities/get-id-param/get-id-param';
import { useEnvironmentFetch } from '../use-environment-fetch/use-environment-fetch';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { useEntityReference } from '../use-entity-reference/use-entity-reference';

export interface UseFindEntityAssetsOnEnvironmentProps {
  /**
   * The target environment to find the assets on
   */
  environment: AuthoringEnvironmentId;
  /**
   * (Optional) The entity type that the assets adhere to.
   * Default is 'config-asset'
   */
  entityType?: string;
}

export const useFindEntityAssetsOnEnvironment = ({
  environment,
  entityType = 'config-asset',
}: UseFindEntityAssetsOnEnvironmentProps) => {
  const {
    endpoints: { get },
    apiUrl,
  } = useEntityReference({ entityType });

  const currentEnvironmentFetch = useEnvironmentFetch(environment, apiUrl);

  const { endpointFetch } = getEntityEndpoint<GetEndpointResponse>(
    get,
    currentEnvironmentFetch,
  );

  const queryClient = useQueryClient();

  const getAssetEntities = async (
    assetIds: string[],
  ): Promise<CopyAssetToEnvironmentResult[]> =>
    Promise.all(
      assetIds.map(async (assetId) => {
        try {
          const response = await queryClient.fetchQuery({
            queryKey: [environment, get.path, assetId],
            queryFn: () =>
              endpointFetch({
                params: getEndpointParamsWithValues([
                  getIdParam(get.pathParameters ?? [], get.path, assetId),
                ]),
              }),
            retry: false,
            staleTime: 0,
          });
          return { id: assetId, data: response, status: 'success' };
        } catch (error) {
          return { id: assetId, error, status: 'error' };
        }
      }),
    );

  return getAssetEntities;
};
