import {
  EnvironmentLabel,
  environmentList,
} from '../../configs/environment-info';

const isEnvironmentLabel = (label: string): label is EnvironmentLabel =>
  label in environmentList;

export const environmentDetailsKeys = [
  'appEnvironment',
  'environmentId',
  'environmentLabel',
  'auth0ClientId',
  'auth0Domain',
  'urls',
];
export const environmentDetailsUrlsKeys = [
  'auth0PrimaryAudience',
  'wsApiUrl',
  'apiUrl',
  'legacyRestApi',
  'contentUrl',
];

export const getEnvironmentDetailsForLabel = (environmentLabel: string) => {
  if (isEnvironmentLabel(environmentLabel)) {
    const details = environmentList[environmentLabel];

    if (typeof details !== 'object') {
      throw new Error(
        `No valid environment details found with label '${environmentLabel}'`,
      );
    }

    environmentDetailsKeys.forEach((key) => {
      if (!(key in details)) {
        throw new Error(
          `Environment details is missing ${key} for label '${environmentLabel}'`,
        );
      }
    });

    environmentDetailsUrlsKeys.forEach((key) => {
      if (!(key in details.urls)) {
        throw new Error(
          `Environment details urls is missing ${key} for label '${environmentLabel}'`,
        );
      }
    });

    return { ...details, label: environmentLabel };
  }

  throw new Error(`${environmentLabel} is not a valid environment label`);
};
