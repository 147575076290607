import type { EntityDetailSchemaWithRelationships } from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';

export const getIncludeEntityTypes = (
  relationships: string[],
  schema: EntityDetailSchemaWithRelationships,
) => {
  const types = relationships.map((relationship) => {
    const { type: entityType } = getRelationshipSchemaItemType(
      relationship,
      schema,
    );
    return entityType;
  });

  return Array.from(new Set(types));
};
