import { AppTenantConfig } from '@web-config-app/core';
import { createTenantConfig } from '../../../utils/create-tenant-config';
import { getEnvironmentDetailsForLabel } from '../../../utils/get-environment-details-for-label/get-environment-details-for-label';

import { pcHealthDomains } from './domains';

const {
  VITE_PCHEALTH_AUTH0_ORGANIZATION_ID,
  VITE_PCHEALTH_BASE_ENVIRONMENT_LABEL,
  VITE_PCHEALTH_PREVIEW_ENVIRONMENT_LABEL,
} = import.meta.env;

const baseEnvironmentDetails = getEnvironmentDetailsForLabel(
  VITE_PCHEALTH_BASE_ENVIRONMENT_LABEL,
);

const previewEnvironmentDetails =
  VITE_PCHEALTH_PREVIEW_ENVIRONMENT_LABEL &&
  getEnvironmentDetailsForLabel(VITE_PCHEALTH_PREVIEW_ENVIRONMENT_LABEL);

export const pcHealthConfig: AppTenantConfig = createTenantConfig({
  core: {
    appEnvironment: baseEnvironmentDetails.appEnvironment,
    contentUrl: baseEnvironmentDetails.urls.contentUrl,
    auth: {
      clientOptions: {
        audience: baseEnvironmentDetails.urls.auth0PrimaryAudience,
        organization: VITE_PCHEALTH_AUTH0_ORGANIZATION_ID,
        domain: baseEnvironmentDetails.auth0Domain,
        client_id: baseEnvironmentDetails.auth0ClientId,
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 1,
    },
    // TODO: Update analytics once we have a working version for our team with all tenants
    analytics: {
      GAPropertyId: 'UA-xxxxxxxx-x',
      segmentKey: 'gqIuNkEL7p5hTUQMfKA1R57GSzgL1xFU',
    },
    api: {
      wsUrl: baseEnvironmentDetails.urls.wsApiUrl,
      url: baseEnvironmentDetails.urls.apiUrl,
      legacyRestApi: baseEnvironmentDetails.urls.legacyRestApi,
    },
  },
  configAppTenantConfig: {
    tenantName: 'PC Health',
    domains: pcHealthDomains,
    authoring: {
      baseEnvironment: {
        apiUrl: baseEnvironmentDetails.urls.apiUrl,
        id: baseEnvironmentDetails.environmentId,
        label: baseEnvironmentDetails.label,
        audience: baseEnvironmentDetails.urls.auth0PrimaryAudience,
      },
      previewEnvironments: previewEnvironmentDetails && [
        {
          id: previewEnvironmentDetails.environmentId,
          apiUrl: previewEnvironmentDetails.urls.apiUrl,
          type: 'preview',
          audience: previewEnvironmentDetails.urls.auth0PrimaryAudience,
        },
      ],
    },
  },
});
