import { useState } from 'react';
import type { Entity } from '@web-config-app/core';
import {
  UseEntityGetOptions,
  useFindEntityInstanceById,
} from '@web-config-app/core-react';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';

export const useAddEntityById = (
  entity: Entity,
  options: UseEntityGetOptions | undefined,
) => {
  const [idValue, setIdValue] = useState<string>('');
  const { currentAuthoringEnvironment } = useEntityDetailsProps();
  const findById = useFindEntityInstanceById({
    entity,
    instanceId: idValue,
    options,
    environment: currentAuthoringEnvironment,
  });

  return { ...findById, idValue, setIdValue };
};
