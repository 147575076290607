import { get } from 'lodash-es';
import jp from 'jsonpath';
import { composePaths } from '@jsonforms/core';
import type {
  EntityDetail,
  TranslationKey,
  AnnotatedJsonSchema,
  EntityDetailSchema,
  RelationshipsSchema,
} from '@web-config-app/core';
import { getFirstUseableLabel } from '@web-config-app/core';
import {
  isPrimitiveArraySchema,
  isPrimitiveObjectSchema,
  isPrimitiveArrayOrPrimitiveObjectSchema,
  isCombinatorObjectSchema,
  computeSchema,
  computeRootNodeSchema,
  addObjectCardAnnotation,
  applyConditionalLogic,
  recalculateRequiredProperties,
  evaluateIncludeIfCondition,
} from '@web-config-app/schema-utils';
import type { Translate } from '../../types/controls';
import type { TreeNode, GetTreeNodeMetadata } from '../../types/tree';
import { getCombinatorProperties } from '../get-combinator-properties/get-combinator-properties.util';

/**
 * constructs a dot-notation path for a property by appending the `property` to
 * the passed `path`
 */

const getPath = (path: string = '', property: string = '') =>
  path.length > 0 ? `${path}.${property}` : property;

/**
 * returns true if `type` is 'array' or 'object'.
 */
const isArrayOrObjectSchema = ({ type }: AnnotatedJsonSchema) =>
  ['object', 'array'].includes(String(type));

/**
 * Helper function to check against primitive/complex array and object schemas
 */

const isComplexArraySchema = (schema: AnnotatedJsonSchema) =>
  schema.type === 'array' && !isPrimitiveArraySchema(schema);

const isComplexObjectSchema = (schema: AnnotatedJsonSchema) =>
  schema.type === 'object' && !isPrimitiveObjectSchema(schema);

const isComplexArrayOrObjectSchema = (schema: AnnotatedJsonSchema) =>
  isComplexObjectSchema(schema) || isComplexArraySchema(schema);

const isEntityDetailSchema = (
  schema: EntityDetailSchema | AnnotatedJsonSchema,
): schema is EntityDetailSchema => Boolean(schema.properties?.attributes);

const schemaTransformers = [
  addObjectCardAnnotation,
  applyConditionalLogic,
  recalculateRequiredProperties,
];

/**
 * Given a tree node, modify the schema to include only properties that we want to display
 * for that node. This will always mean removing any properties of type `array` but will could
 * also remove property of type `object` if any of THAT object's properties are also of type
 * `object`. Essentially, we only want to include object properties if they are an object of
 * only primitive types.
 *
 * The result will be a shallow slice of schema spanning between one and two levels of the schema.
 */

export const getChildNodes = (
  schema: AnnotatedJsonSchema,
  {
    /**
     * path is the dot-notation path to the current schema (relative to root)
     */
    path = '',
    /**
     * the property name of the value for `schema`. This will feel a bit twisty, but in
     * this example:
     *
     * properties: {
     *   someParent: { // a property schema, equal to the `schema` argument here.
     *      type: ...,
     *      title: ...,
     *      ...etc
     *   }
     * }
     *
     * the value for `currentSchemaProperty` is 'someParent'
     */
    currentSchemaPropertyName = '',
    isRoot,
    onSchemaPropertyRemoved,
  }: GetTreeNodeMetadata,
  data?: any,
  relationshipsSchema?: RelationshipsSchema,
  translate: Translate = (id?: string) => id,
) => {
  const childNodes: TreeNode[] = [];
  if (schema.type === 'object') {
    /**
     * The flow here tends to get a bit brain-twisting because are are working on
     * two levels of schema at once, albeit doing different things. Here, we have a
     * schema of type object, so we will iterate over its properties.
     */

    Object.entries(schema?.properties ?? {}).forEach(
      ([propertyName, propertySubSchema]: [string, AnnotatedJsonSchema]) => {
        /**
         * We only want to add nodes to the tree for types of `object` or `array` that
         * represent complex data structures and meet the includeIf condition.
         */

        if (
          (isComplexArrayOrObjectSchema(propertySubSchema) ||
            (isPrimitiveArrayOrPrimitiveObjectSchema(propertySubSchema) &&
              isRoot)) &&
          propertySubSchema['x-entity-presentation']?.hidden !== true &&
          evaluateIncludeIfCondition({
            schema: propertySubSchema,
            data,
            options: {
              onSchemaPropertyRemoved,
              path,
              schemaPropertyName: propertyName,
            },
          })
        ) {
          /**
           * append the property name to current path to build the path for
           * this property.
           */
          const pathForSchema = getPath(path, propertyName);
          /**
           * Get a translated name to display in the tree for this node
           */
          const propertyLabelKey = propertySubSchema['x-entity-label']?.key;
          const propertyNodeName = propertyLabelKey
            ? translate(propertyLabelKey)
            : translate('MISSING_LABEL_ANNOTATION', {
                path: composePaths(pathForSchema, propertyName),
              });

          /**
           * We are in the process of creating nodes in the tree, which can ONLY be of types
           * complex objects and arrays EXCEPT when processing direct children of the root
           * node, in which case we also include primitive objects.
           */
          if (isCombinatorObjectSchema(propertySubSchema)) {
            const propertyData: any = get(data, pathForSchema);
            const { combinatorSubSchemaMap, combinatorDiscriminator } =
              getCombinatorProperties(propertySubSchema, translate) ?? {};

            if (combinatorDiscriminator && combinatorSubSchemaMap) {
              const selectedSubSchema =
                combinatorSubSchemaMap[propertyData?.[combinatorDiscriminator]];
              if (selectedSubSchema) {
                /**
                 * TODO: https://everlong.atlassian.net/browse/CACT-1371
                 * Some refinement is required to properly calculate the tree and schemas
                 * for combinator object
                 */
                const children = getChildNodes(
                  selectedSubSchema,
                  {
                    path: pathForSchema,
                    currentSchemaPropertyName: propertyName,
                    onSchemaPropertyRemoved,
                  },
                  data,
                  relationshipsSchema,
                  translate,
                );

                children.forEach((child: TreeNode) => {
                  childNodes.push(child);
                });
              }
            }
          } else if (
            isComplexObjectSchema(propertySubSchema) ||
            (isPrimitiveObjectSchema(propertySubSchema) && isRoot)
          ) {
            const schemaForNode = computeSchema(
              propertySubSchema,
              data,
              schemaTransformers,
              {
                relationshipsSchema,
                originalSchema: schema.properties?.[propertyName],
                path: pathForSchema,
                onSchemaPropertyRemoved,
              },
            );

            childNodes.push({
              children: getChildNodes(
                propertySubSchema,
                {
                  path: pathForSchema,
                  currentSchemaPropertyName: propertyName,
                  onSchemaPropertyRemoved,
                },
                data,
                relationshipsSchema,
                translate,
              ),
              type: 'nestedObject',
              name: propertyNodeName,
              id: pathForSchema,
              data: {
                schema: schemaForNode,
              },
            });
          } else if (isComplexArraySchema(propertySubSchema)) {
            childNodes.push({
              name: propertyNodeName,
              id: pathForSchema,
              children: getChildNodes(
                propertySubSchema,
                {
                  path,
                  currentSchemaPropertyName: propertyName,
                  onSchemaPropertyRemoved,
                },
                data,
                relationshipsSchema,
                translate,
              ),
              type: 'array',
              data: {
                schema: computeSchema(
                  propertySubSchema,
                  data,
                  schemaTransformers,
                  {
                    relationshipsSchema,
                    path: pathForSchema,
                    originalSchema: schema.properties?.[propertyName],
                    onSchemaPropertyRemoved,
                  },
                ),
              },
            });
          }
        }
      },
    );
  } else if (isComplexArraySchema(schema)) {
    /**
     * Here we need to rely on the `schemaPropertyName` as passed in the function arguments
     * since we don't have access to it immediately like we do in the `schema.type === 'object'
     * branch above
     */

    const dataPath = getPath(path, currentSchemaPropertyName);
    const propertyData: any = get(data, dataPath);

    /**
     * We want to handle array properties with some special handling that adds a tree item for every item
     * contained in the entity's data for that array property.
     */

    const { combinatorSubSchemaMap, combinatorDiscriminator } =
      getCombinatorProperties(schema, translate) ?? {};

    return propertyData?.length > 0
      ? propertyData.map((item: any, idx: number) => {
          const itemSchema = (
            combinatorDiscriminator
              ? combinatorSubSchemaMap?.[item[combinatorDiscriminator]]
              : schema.items
          ) as AnnotatedJsonSchema;

          const itemDataPath = `${dataPath}.${idx}`;
          const { key, propertyRef, arrayItemNameKey } =
            itemSchema['x-entity-label'] ??
            (schema.items as AnnotatedJsonSchema)['x-entity-label'] ??
            {};

          /**
           * if we get a value for `propertyRef`, it's a JSON path to a property from which
           * we want to grab the value to display as the tree node's name
           *
           * If `propertyRef` is not set OR if there is no value in data yet for that path then
           * default to showing a more generic label, usually by using the translation key
           * set in the annotation for `arrayItemNameKey`
           */
          const itemPropertyRefDataValue =
            propertyRef && typeof item === 'object'
              ? jp.value(item, propertyRef)
              : null;

          const itemLabelKey = arrayItemNameKey ?? key;
          return {
            name: getFirstUseableLabel(
              itemPropertyRefDataValue,
              itemLabelKey
                ? translate(itemLabelKey)
                : translate('MISSING_LABEL_ANNOTATION', {
                    path: composePaths(itemDataPath, currentSchemaPropertyName),
                  }),
              schema.title,
              currentSchemaPropertyName,
            ),
            id: itemDataPath,
            type: 'arrayItem',
            children: getChildNodes(
              itemSchema as AnnotatedJsonSchema,
              {
                path: itemDataPath,
                onSchemaPropertyRemoved,
              },
              data,
              relationshipsSchema,
              translate,
            ),
            data: {
              schema: computeSchema(itemSchema, data, schemaTransformers, {
                relationshipsSchema,
                arrayItem: {
                  isArrayItemContext: true,
                  arrayItemData: item,
                },
                path: itemDataPath,
                originalSchema: itemSchema,
                onSchemaPropertyRemoved,
                nextSchemaLevelOptions: {
                  haltRecursionTest: (testSchema) =>
                    testSchema.type !== 'array',
                },
              }),
            },
          };
        })
      : [];
  }

  return childNodes;
};

const getEntityDetailSchemaProperties = (schema: EntityDetailSchema) => {
  const { attributes: treeSchema } = schema.properties;
  const relationshipsSchema = schema.properties
    .relationships as RelationshipsSchema;

  return { treeSchema, relationshipsSchema };
};

const getGenericSchemaProperties = (schema: AnnotatedJsonSchema) => ({
  treeSchema: schema,
  relationshipsSchema: undefined,
});

export interface CreateEntityTreeArgs {
  schema: AnnotatedJsonSchema;
  data?: EntityDetail | any;
  onSchemaPropertyRemoved?: (dataPath: string, propertyName: string) => void;
}

export const createEntityTree = (
  { schema, data, onSchemaPropertyRemoved }: CreateEntityTreeArgs,
  translate: Translate = (id?: TranslationKey) => id,
): TreeNode[] => {
  /**
   * To keep this function more generic, we allow any schema to be passed and processed.
   * In practice, the EntityForm should always be passing an EntityDetailSchema
   */

  const isDetailSchema = isEntityDetailSchema(schema);
  const { treeSchema, relationshipsSchema } = isDetailSchema
    ? getEntityDetailSchemaProperties(schema)
    : getGenericSchemaProperties(schema);

  const rootData = isDetailSchema ? data?.attributes : data;

  const rootNodeSchema = computeSchema(
    treeSchema,
    data,
    [
      computeRootNodeSchema,
      applyConditionalLogic,
      recalculateRequiredProperties,
    ],
    {
      relationshipsSchema,
      recursive: false,
      onSchemaPropertyRemoved,
      path: '',
    },
  );

  const rootNodeLabelKey = treeSchema['x-entity-label']?.key;
  const rootNode: TreeNode = {
    id: 'root',
    type: 'root',
    name: rootNodeLabelKey
      ? translate(rootNodeLabelKey)
      : translate('MISSING_LABEL_ANNOTATION', { path: 'attributes' }),
    data: {
      schema: rootNodeSchema,
    },
  };

  const childNodes = getChildNodes(
    treeSchema,
    { path: '', isRoot: true, onSchemaPropertyRemoved },
    rootData,
    relationshipsSchema,
    translate,
  );
  const rootSchemaProperties = Object.values(
    rootNodeSchema.properties ?? {},
  ) as AnnotatedJsonSchema[];
  return rootSchemaProperties.every((property: AnnotatedJsonSchema) =>
    isArrayOrObjectSchema(property),
  )
    ? childNodes
    : [
        {
          ...rootNode,
          children: childNodes,
        },
      ];
};
