import React from 'react';
import type { WorkflowAction } from '@web-config-app/core';
import { Button } from '@leagueplatform/genesis-core';

export type EntityActionButtonProps = Omit<
  WorkflowAction,
  'confirmationModal' | 'type'
> & {
  buttonPriority: 'primary' | 'secondary';
};

export const EntityActionButton = ({
  action,
  disabled,
  label,
  display,
  buttonPriority,
}: EntityActionButtonProps) => {
  if (!display) return null;

  return (
    <Button
      priority={buttonPriority}
      size="medium"
      disabled={disabled}
      onClick={() => action?.()}
    >
      {label}
    </Button>
  );
};
