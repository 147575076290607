import { useCallback } from 'react';
import {
  QueryObserverSuccessResult,
  QueryObserverRefetchErrorResult,
  QueryObserverLoadingErrorResult,
} from '@tanstack/react-query';
import type {
  AuthoringEnvironmentId,
  Entity,
  GetEndpointResponse,
} from '@web-config-app/core';
import {
  UseEntityGetOptions,
  useEntityGet,
} from '../use-entity-get/use-entity-get';

interface UseFindEntityInstanceByIdArgs {
  entity: Entity;
  instanceId: string;
  environment: AuthoringEnvironmentId;
  options?: UseEntityGetOptions;
}

interface FindEntityByIdArgs {
  onSuccess: (
    res: QueryObserverSuccessResult<GetEndpointResponse, Error>,
  ) => void;
  onError?: (
    res:
      | QueryObserverRefetchErrorResult<GetEndpointResponse, Error>
      | QueryObserverLoadingErrorResult<GetEndpointResponse, Error>,
  ) => void;
}

export function useFindEntityInstanceById({
  entity,
  instanceId,
  options,
  environment,
}: UseFindEntityInstanceByIdArgs) {
  const useQueryResult = useEntityGet({
    entity,
    instanceId,
    options,
    environment,
  });
  const { refetch } = useQueryResult;

  const findEntityById = useCallback(
    async ({ onSuccess, onError }: FindEntityByIdArgs) => {
      if (!instanceId) return;

      const res = await refetch();
      const getSuccess = res.status === 'success' && res.data.data;

      if (getSuccess) {
        onSuccess(res);
      } else if (res.status === 'error') {
        onError?.(res);
      }
    },
    [instanceId, refetch],
  );

  return {
    ...useQueryResult,
    findEntityById,
  };
}
