import { useMemo } from 'react';
import type {
  AnnotatedJsonSchema,
  EntityPresentationAnnotation,
  Nullable,
} from '@web-config-app/core';
import type {
  EntityFormControlProps,
  DataSourceProps,
  Translate,
} from '../../types/controls';
import { useTranslate } from '../use-translate/use-translate';
import { getCombinatorProperties } from '../../utils/get-combinator-properties/get-combinator-properties.util';

const getLabelAnnotations = (schema: AnnotatedJsonSchema) =>
  schema['x-entity-label'] ?? {};
const getPresentationAnnotations = (schema: AnnotatedJsonSchema) =>
  schema['x-entity-presentation'] ?? {};

const ENUM_CONTROL_PLACEHOLDER_VALUE = 'enumControlPlaceholder';

/**
 * enumOptions apply to the SelectInputControl and provide an array of GDSSelectOptions in one of
 * two ways:
 *
 * 1. localized labels for each raw value in the schema's `enum`.
 * 2. provided by a data source
 */

const getEnumOptions = (
  enumLabels: EntityPresentationAnnotation['enumLabels'] | undefined,
  schema: AnnotatedJsonSchema,
  dataSourceEnumOptions: DataSourceProps['enumOptions'],
  translate: Translate,
) => {
  if (dataSourceEnumOptions) return dataSourceEnumOptions;

  return enumLabels && Array.isArray(schema.enum)
    ? Object.entries(enumLabels)
        .filter(([value]) => {
          if (schema.enum?.includes(value)) {
            return true;
          }

          if (value === ENUM_CONTROL_PLACEHOLDER_VALUE) {
            return false;
          }

          /**
           * We have an enumLabels map that contains a value that doesn't exist in the actual
           * schema's enum value
           */
          console.warn(
            `received enumLabel for value ${value} that does not exist in schema.enum`,
          );
          return false;
        })
        .map(([value, translationKey]) => ({
          label: translate(translationKey),
          value,
        }))
    : schema.enum?.map((item) => ({ label: item, value: item }));
};

export function mapAnnotatedSchemaToProps(
  schema: AnnotatedJsonSchema,
  dataSourceProps: Nullable<DataSourceProps> | undefined,
  translate: Translate,
  path: string,
): Partial<EntityFormControlProps> {
  const { key: labelTranslationKey, propertyRef: labelPropertyRef } =
    getLabelAnnotations(schema);
  const {
    hint,
    banner,
    hidden,
    enumLabels,
    fieldGroups,
    fieldOrder,
    arrayAddLabel,
  } = getPresentationAnnotations(schema);

  const localized = schema['x-entity-localized'] ?? false;
  const enumOptions = getEnumOptions(
    enumLabels,
    schema,
    dataSourceProps?.enumOptions,
    translate,
  );
  const enumPlaceholder =
    enumOptions &&
    translate(enumLabels?.enumControlPlaceholder ?? 'DEFAULT_ENUM_PLACEHOLDER');

  /**
   * combinatorProperties are created when the schema includes a discriminator property and a `oneOf` array
   * The combinator properties include:
   * - the discriminator property name
   * - the options for the select input used for selecting a subschema option
   * - the sub-schemas to be rendered upon option selection
   * - a placeholder for the select input
   * - properties to be added to the primitive control form field wrapping the combinator select input
   */

  const combinatorProperties = getCombinatorProperties(schema, translate);

  /**
   * When virtually grouping properties in an Object control, this returns
   * an array of field groups with a localized label for the fieldset's legend
   */
  const fieldGroupsWithLegends = fieldGroups?.map(
    ({ labelKey, ...fieldGroup }) => ({
      ...fieldGroup,
      legend: translate(labelKey?.key, labelKey?.values)!,
    }),
  );

  return {
    banner: banner && {
      ...banner,
      title: translate(banner.title?.key, banner.title?.values),
      description: translate(
        banner.description?.key,
        banner.description?.values,
      ),
    },
    enumOptions,
    enumPlaceholder,
    fieldGroups: fieldGroupsWithLegends,
    fieldOrder,
    hidden: Boolean(hidden), // if undefined, coerce to false
    hint: translate(hint?.key, hint?.values),
    label: labelTranslationKey
      ? translate(labelTranslationKey)
      : translate('MISSING_LABEL_ANNOTATION', { path }),
    arrayAddLabel: translate(arrayAddLabel),
    labelPropertyRef,
    localized,
    combinatorProperties,
  };
}

export type UseAnnotatedSchemaProps = Partial<EntityFormControlProps>;

export type AnnotatedSchemaProps = ReturnType<typeof useAnnotatedSchemaProps>;

export const useAnnotatedSchemaProps = (
  schema: AnnotatedJsonSchema,
  dataSourceProps?: Nullable<DataSourceProps>,
  path: string = '',
) => {
  const translate = useTranslate();
  return useMemo(
    () => mapAnnotatedSchemaToProps(schema, dataSourceProps, translate, path),
    [schema, dataSourceProps, translate, path],
  );
};
