import * as React from 'react';
import { Tooltip } from '@leagueplatform/genesis-core';
import {
  WorkflowActionModal,
  MenuItemWorkflowAction,
} from '@web-config-app/core';
import {
  ConfirmationModal,
  SelectActionMenuItemContents,
} from '@web-config-app/core-react-ui';

interface EntityMenuItemProps {
  item: MenuItemWorkflowAction;
}

export const EntityMenuItem: React.FC<EntityMenuItemProps> = ({ item }) => {
  const { disabled, label, tooltip, modal } = item;

  if (disabled && tooltip) {
    return (
      <Tooltip
        align="center"
        avoidCollisions
        content={tooltip}
        side="left"
        sideOffset={30}
      >
        <SelectActionMenuItemContents disabled={disabled}>
          {label}
        </SelectActionMenuItemContents>
      </Tooltip>
    );
  }

  if (modal?.type === WorkflowActionModal.confirmation) {
    return (
      <ConfirmationModal
        modalTrigger={
          <SelectActionMenuItemContents
            disabled={disabled}
            color="$onSurfaceTextCritical"
          >
            {label}
          </SelectActionMenuItemContents>
        }
        title={modal?.title}
        description={modal?.description}
        confirmCtaText={modal?.confirmCtaText}
        confirmCtaAction={modal?.confirmCtaAction}
      />
    );
  }
  return <SelectActionMenuItemContents>{label}</SelectActionMenuItemContents>;
};
