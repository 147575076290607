import { useMemo } from 'react';
import { LayoutProps } from '@jsonforms/core';
import { useAnnotatedSchemaProps } from '../use-annotated-schema-props/use-annotated-schema-props';
import type { EntityFormLayoutProps } from '../../types/layouts';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';
import { getComposedSchemaPropertyPath } from '../../utils/get-composed-schema-property-path/get-composed-schema-property-path';

/**
 * This hook takes the default props passed to layouts via @jsonforms/react
 * withJsonFormsLayoutProps and enhances them with props from our own custom
 * annotations implementation, which are present in `props.schema`
 *
 * @param props - LayoutProps passed to all layouts by JsonSchemaDispatch
 * @returns {@link EntityFormLayoutProps `EntityFormLayoutProps`}
 */

export const useEntityFormLayout = (
  props: LayoutProps,
): EntityFormLayoutProps => {
  const { formPath } = useEntityDetailsProps();
  /**
   * transform custom annotations into props for our form layouts and
   * get translated strings
   */
  const annotationProps = useAnnotatedSchemaProps(
    props.schema,
    null,
    getComposedSchemaPropertyPath(formPath, props.path),
  );

  return useMemo(
    () => ({
      ...props,
      ...annotationProps,
    }),
    [props, annotationProps],
  );
};
