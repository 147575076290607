import { SchemaTransformerArgs, SchemaTransformer } from '@web-config-app/core';

/**
 * Finds schema nodes that are image asset ID references (NOT url based assets)
 *
 * Will call onSchemaPropertyMatch with the computed path if the schema node is an image asset ID reference
 * but not if the schema includes the format property (indicating a url based asset)
 */

export const findImageAssetIdSchemaNodes: SchemaTransformer = ({
  schema,
  options,
}: SchemaTransformerArgs) => {
  const { onSchemaPropertyMatch, computePath } = options ?? {};

  const entityAssetType = schema['x-entity-asset']?.type;
  const { format } = schema;
  if (
    entityAssetType === 'image' &&
    computePath &&
    onSchemaPropertyMatch &&
    !format
  ) {
    onSchemaPropertyMatch(computePath);
  }

  return schema;
};
