export const EN_QUESTION_CODE_STRINGS = {
  QUESTION_CODE: '{count, plural, one {Question Code} other {Question Codes}}',
  QUESTION_CODE_ENTITY_NAME: 'Question Code',
  QUESTION_CODE_LABEL: 'Question Code',
  QUESTION_CODE_NAME_LABEL: 'Name',
  QUESTION_CODE_NAME_HINT: 'Displays a short name for the question code',
  QUESTION_CODE_CODE_LABEL: 'Code',
  QUESTION_CODE_CODE_HINT: 'Displays the actual question code',
  QUESTION_CODE_CODE_SET_LABEL: 'Set',
  QUESTION_CODE_CODE_SET_HINT:
    'Displays the code set (system). Could be LOINC, League or Customer Custom',
  QUESTION_CODE_STATUS_LABEL: 'Status',
  QUESTION_CODE_STATUS_HINT: 'Displays the Status of the Question Code',
  QUESTION_CODE_QA_BANNER_TITLE: 'These configurations are under development.',
  QUESTION_CODE_QA_BANNER_DESCRIPTION:
    'Please do not use to create the question for questionnaire.',
  QUESTION_CODE_CODE_SET_LOINC: 'LOINC',
  QUESTION_CODE_CODE_SET_LEAGUE: 'LEAGUE',
  QUESTION_CODE_CODE_SET_CUSTOMER_CUSTOM: 'CUSTOMER CUSTOM',
};
