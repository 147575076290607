import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Popover } from '@leagueplatform/genesis-core';
import { IconButton } from '../icon-button/icon-button.component';
import { EnvironmentInfo } from '../environment-info/environment-info.component';
import type { EnvironmentInfoProps } from '../environment-info/environment-info.component';

/**
 * A popover component that displays environment information.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.environment - The current environment information to display
 * @param {boolean} props.multiEnvironmentAuthoringEnabled - Flag indicating if multi-environment authoring is enabled
 *
 * @returns {JSX.Element} A popover component containing environment information with an icon button trigger
 *
 * @example
 * ```tsx
 * <EnvironmentInfoPopover
 *   environment="production"
 *   multiEnvironmentAuthoringEnabled={true}
 * />
 * ```
 */

export const EnvironmentInfoPopover: React.FC<EnvironmentInfoProps> = ({
  environment,
  multiEnvironmentAuthoringEnabled,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Popover.Root>
      <Popover.Trigger>
        <IconButton
          icon="statusInformationCircle2"
          label={formatMessage({ id: 'ENVIRONMENT_INFO' })}
        />
      </Popover.Trigger>
      <Popover.Content
        align="end"
        side="bottom"
        css={{ width: '375px' }}
        showCloseButton={false}
      >
        <EnvironmentInfo
          environment={environment}
          multiEnvironmentAuthoringEnabled={multiEnvironmentAuthoringEnabled}
        />
      </Popover.Content>
    </Popover.Root>
  );
};
