import { useParams } from '@leagueplatform/routing';
import type { AuthoringEnvironmentId } from '@web-config-app/core';

export interface ConfigAppParams {
  entityId?: string;
  domainId?: string;
  environmentId?: AuthoringEnvironmentId;
  entityInstanceId?: string;
}

export function useConfigAppParams<
  Params extends { [K in keyof Params]?: string } = ConfigAppParams,
>() {
  const params = useParams<Params>();

  return params;
}
