/**
 * Helper function to format the HTML content from Tiptap editor. Tiptap (our rich text editor) uses ProseMirror under the hood, which adds <p> tags within every <li> element. There is no good way to override this via elements created, especially if we want to preserve the behavior of having nested lists.
 * This solution removes the <p> tags via replaceAll regex, while preserving nested lists.
 *
 * @param {string} htmlString - The HTML content within a string from the editor.
 * @returns {string} The formatted html string
 */

export const removeParagraphTagsInsideLi = (html: string) => {
  const newHtml = html.replaceAll(
    /<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi,
    '<li>$1<$2$3>',
  );
  return newHtml;
};
